import logo from './logo.svg';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BrowserRouter, Outlet, Route, Routes, useLocation, useMatch, useNavigate, useResolvedPath } from 'react-router-dom';
import Inquiries from './screens/Inquiries';
import Overview from './screens/Overview';
import Offers from './screens/Offers';
import Bookings from './screens/Bookings';
import Customers from './screens/Customers';
import Messages, { Chat } from './screens/Messages';
import Inquiry from './screens/Inquiry';
import Offer from './screens/Offer';
import { useContext, useEffect, useState } from 'react';
import Booking from './screens/Booking';
import Customer from './screens/Customer';
import Expenses from './screens/Expenses';
import Debug from './screens/Debug';
import { AuthContext } from './providers/AuthProvider';
import 'react-toastify/dist/ReactToastify.css';
import Header from './components/Header';
import Emails from './screens/Emails';
import VersionControl, { VersionLog } from './providers/VersionControl';
import Statistics from './screens/Statistics';
function App() {
	return (
		<BrowserRouter future={{ v7_startTransition: true }}>
			<Routes>
				<Route path="/" element={<Main />}>
					<Route index element={<Overview />} />
					<Route path="inquiries" element={<Inquiries />} />
					<Route path="inquiries/:id" element={<Inquiry />} />

					<Route path="offers" element={<Offers />} />
					<Route path="offers/:id" element={<Offer />} />
					<Route path="bookings" element={<Bookings />} />
					<Route path="bookings/:id" element={<Booking />} />
					<Route path="customers" element={<Customers />} />
					<Route path="customers/:id" element={<Customer />} />
					<Route path="expenses" element={<Expenses />} />
					<Route path="messages" element={<Messages />}>
						<Route path=":conversation" element={<Chat />} />

					</Route>
					<Route path="emails" element={<Emails />} />
					<Route path="statistics" element={<Statistics />} />
					<Route path="debug" element={<Debug />} />
				</Route>
			</Routes>
		</BrowserRouter>
	)
}

function Main() {
	const { reloadNotifications } = useContext(AuthContext)
	const [menuOpen, setMenuOpen] = useState(false)
	const location = useLocation();

	useEffect(() => {
		reloadNotifications()
	}, [location]);


	return (
		<div className="app">

			<VersionControl>
				<Header
					setMenuOpen={setMenuOpen}
					menuOpen={menuOpen}
				/>
				<main>
					<div className={`sidebar ${menuOpen ? 'sidebar-open' : ''}`}>
						<Sidebar menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
						<VersionLog />
					</div>
					<div className="main">
						<Outlet />
					</div>
				</main>
			</VersionControl>
		</div>
	);
}

function Sidebar({ menuOpen, setMenuOpen }) {
	const { notifications, user } = useContext(AuthContext)

	const items = [
		{
			icon: 'home',
			title: 'Overview',
			url: '/',
			notifications: 0
		},
		{
			icon: 'envelope',
			title: 'Inquiries',
			url: '/inquiries',
			notifications: notifications?.inquiries
		},
		{
			icon: 'file-lines',
			title: 'Offers',
			url: '/offers',
			notifications: notifications?.offers
		},
		{
			icon: 'globe-pointer',
			title: 'Bookings',
			url: '/bookings',
			notifications: notifications?.bookings
		},
		{
			icon: 'user-group',
			title: 'Customers',
			url: '/customers',
			notifications: 0
		},
		{
			icon: 'money-bill',
			title: 'Expenses',
			url: '/expenses',
			notifications: 0
		},
		{
			icon: 'comment-lines',
			title: 'Messages',
			url: '/messages',
			notifications: notifications?.messages
		},
		{
			icon: 'envelopes-bulk',
			title: 'Emails',
			url: '/emails',
			notifications: notifications?.emails
		}
	]

	if(user?.superadmin){
		items.push({
			icon: 'chart-simple',
			title: 'Statistics',
			url: '/statistics',
			notifications: 0
		})
	}

	const ListItem = ({ icon, title, url, notifications }) => {
		let resolved = useResolvedPath();
		const navigate = useNavigate()

		const path = `/${resolved.pathname.split('/')[1]}`

		

		return (
			<li onClick={() => {
				navigate(url)
				if (menuOpen) setMenuOpen(false)
			}} className={`row gx-0 align-items-center ${path === url ? 'active' : ''}`}>
				<span className='col-auto sidebar-icon me-3'><FontAwesomeIcon fixedWidth icon={['far', icon]} /></span>
				<span className='col sidebar-title'>{title}</span>
				{notifications && notifications > 0 ? <span className='col-auto sidebar-notification mx-4'>{notifications}</span> : null}
			</li>
		)
	}
	return (

		<ul>
			{
				items.map((item, key) =>
					<ListItem key={key} {...item} />
				)
			}
		</ul>

	)
}

export default App;
