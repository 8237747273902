// Funksjon for å generere tilfeldig dato fremover i tid
const allFirstNames = [
	"Mattis",
	"Kristian",
	"Petrus",
	"Tiril",
	"Silje",
	"Stine",
	"Niko",
	"Heidi",
	"Ann Kristin",
	"Thor",
  "Emil",
  "Olivia",
  "Liam",
  "Astrid",
  "Noah",
  "Ella",
  "Lucas",
  "Wilma",
  "William",
  "Maja",
  "Oscar",
  "Nora",
  "Hugo",
  "Emma",
  "Elias",
  "Alice",
  "Oliver",
  "Isabella",
  "Axel",
  "Elsa",
  "Filip",
  "Ida",
  "Alexander",
  "Alma",
  "Lukas",
  "Elin",
  "Viktor",
  "Julia",
  "Leo",
  "Selma",
  "Theo",
  "Ellen",
  "Charlie",
  "Hanna",
  "Samuel",
  "Ebba",
  "Liam",
  "Linnea",
  "Erik",
  "Signe",
  "Felix",
  "Vera",
  "Ludvig",
  "Emilia",
  "Anton",
  "Agnes",
  "Isak",
  "Liv",
  "Sebastian",
  "Ebba",
  "Karl",
  "Elvira",
  "Gustav",
  "Saga",
  "Albin",
  "Lea",
  "Nils",
  "Molly",
  "Milo",
  "Alva",
  "Ebbe",
  "Stella",
  "Vincent",
  "Lilly",
  "Elliot",
  "Sofia",
  "Theodor",
  "Maja",
  "Adrian",
  "Ella",
  "Melvin",
  "Nellie",
  "Alvin",
  "Hedda",
  "Wilmer",
  "Evelina",
  "Jacob",
  "Tyra",
  "Joel",
  "Ester",
  "Isac",
  "My",
  "Jonathan",
  "Nova",
  "Eddie",
  "Tuva",
  "Benjamin",
  "Tilde",
  "Noel",
  "Ingrid",
  "Ville",
  "Elise",
  "Sixten",
  "Sara",
  "Elton",
  "Frida",
  "Julian",
  "Ella"
]

const allLastNames = [
  "Andersen",
  "Johansson",
  "Olsen",
  "Larsson",
  "Eriksson",
  "Hansen",
  "Persson",
  "Nielsen",
  "Svensson",
  "Pedersen",
  "Gustafsson",
  "Karlsson",
  "Olofsson",
  "Møller",
  "Andersson",
  "Kristiansen",
  "Lund",
  "Jensen",
  "Madsen",
  "Sørensen",
  "Berg",
  "Holm",
  "Petersen",
  "Jonsson",
  "Nilsen",
  "Lindberg",
  "Håkansson",
  "Dahl",
  "Mortensen",
  "Lindström",
  "Olesen",
  "Bergström",
  "Jansson",
  "Åberg",
  "Lundgren",
  "Sundström",
  "Rasmussen",
  "Bergman",
  "Kjellsson",
  "Pålsson",
  "Eklund",
  "Jakobsen",
  "Gundersen",
  "Henriksen",
  "Haugen",
  "Christensen",
  "Søberg",
  "Thomsen",
  "Nygård",
  "Ahmed",
  "Holmberg",
  "Mikkelsen",
  "Olesen",
  "Antonsen",
  "Ström",
  "Eriksen",
  "Larsen",
  "Mäkinen",
  "Aronsson",
  "Kristensen",
  "Hagström",
  "Sjöberg",
  "Ottosen",
  "Tornqvist",
  "Hermansson",
  "Lorentzen",
  "Hansen",
  "Petersson",
  "Björk",
  "Isaksson",
  "Nordström",
  "Hovland",
  "Fransson",
  "Andresen",
  "Åkesson",
  "Johannessen",
  "Viklund",
  "Haugland",
  "Magnusson",
  "Berglund",
  "Wahlström",
  "Eskelsen",
  "Åberg",
  "Ottosson",
  "Sundqvist",
  "Olofsen",
  "Bengtsson",
  "Johannesson",
  "Nordlund",
  "Henriksson",
  "Pedersen",
  "Jokinen",
  "Ekelund",
  "Jørgensen",
  "Høgmoen",
  "Tommerås",
  "Christensen",
  "Foss",
  "Berg",
  "Fauske",
  "Rogne",
  "Glærum",
  "Westerås",
  "Beatrix",
  "Uglum"
]

function randomFutureDate() {
    const today = new Date();
    const futureDate = new Date(today.getTime() + Math.random() * 365 * 24 * 60 * 60 * 1000); // Legger til et tilfeldig antall dager
    return futureDate.toISOString().split('T')[0]; // Format: Y-m-d
}

export function randomPastDate() {
  const today = new Date();
  const futureDate = new Date(today.getTime() - Math.random() * 365 * 24 * 60 * 60 * 1000); // Legger til et tilfeldig antall dager
  return futureDate.toISOString().split('T')[0]; // Format: Y-m-d
}
  // Funksjon for å generere tilfeldig fullt navn
 export function randomFullName() {
    const firstNames = ["John", "Alice", "Bob", "Emma", "David", "Olivia", "Liam", "Sophia", "Noah", "Ava"];
    const lastNames = ["Smith", "Johnson", "Brown", "Wilson", "Lee", "Martinez", "Garcia", "Davis", "Taylor", "Thomas"];
    const randomFirstName = allFirstNames[Math.floor(Math.random() * allFirstNames.length)];
    const randomLastName = allLastNames[Math.floor(Math.random() * allLastNames.length)];
    return `${randomFirstName} ${randomLastName}`;
  }
  
  // Funksjon for å generere tilfeldig identifikator på over 1000
  function randomId() {
    return Math.floor(1000 + Math.random() * 9000);
  }
  
  // Funksjon for å generere tilfeldig boolean-verdi (true eller false)
  export function randomBoolean() {
    return Math.random() < 0.5;
  }
  
  // Liste med mulige safariopplevelser
const safariExperiences = [
    "Safari i Serengeti", 
    "Jungelsafari i Amazonas", 
    "Safari i Masai Mara", 
    "Ørkensafari i Namibia", 
    "Safari i Kruger nasjonalpark", 
    "Safari i Pantanal", 
    "Savannesafari i Sør-Afrika", 
    "Regnskogsafari i Borneo", 
    "Safari i Okavango-deltaet", 
    "Safari i Yellowstone nasjonalpark"
  ];
  
  

export function getRandomInquiries(){
    // Generer array med objekter
    const numberOfObjects = 87; // Endre dette tallet for å få mer enn 20 objekter
    const objectsArray = [];

    for (let i = 0; i < numberOfObjects; i++) {
        const obj = {
            id: randomId(),
            date: randomFutureDate(),
            customer: randomFullName(),
            channel: Math.random() < 0.5 ? 'NO' : 'SE',
            trip: safariExperiences[Math.floor(Math.random() * safariExperiences.length)],
            seen: randomBoolean(),
            status: ['draft', 'open', 'offer-sent'][Math.floor(Math.random() * 3)]
        };
        objectsArray.push(obj);
    }

    return objectsArray
}

function randomFormattedNumber(channel) {
    const min = 50;
    const max = 1000; // Du kan endre dette maksimumsbeløpet etter behov
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    const formattedNumber = (randomNumber * 1000).toLocaleString('nb-NO'); // Bruker en-US-lokalisering for komma som tusentalls separator
    return (channel === 'NO' ? 'NOK' : 'SEK') + ' ' + formattedNumber;
}

function randomLargeSum() {
    const min = 10000;
    const max = 999999; // Du kan endre dette maksimumsbeløpet etter behov
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
	return randomNumber
}
export function getRandomOffers(){
    // Generer array med objekter
    const numberOfObjects = 65; // Endre dette tallet for å få mer enn 20 objekter
    const objectsArray = [];

    for (let i = 0; i < numberOfObjects; i++) {

        const channel = Math.random() < 0.5 ? 'NO' : 'SE'
        const obj = {
            id: `${randomId()}-1`,
            date: randomFutureDate(),
            customer: randomFullName(),
            channel: channel,
            name: safariExperiences[Math.floor(Math.random() * safariExperiences.length)],
            seen: randomBoolean(),
            seller: randomFullName(),
            total: randomFormattedNumber(channel),
            status: ['draft', 'accepted', 'for-review', 'unopened'][Math.floor(Math.random() * 4)]
        };
        objectsArray.push(obj);
    }

    return objectsArray
}

export function getRandomBookings(){
  // Generer array med objekter
  const numberOfObjects = 32; // Endre dette tallet for å få mer enn 20 objekter
  const objectsArray = [];

  for (let i = 0; i < numberOfObjects; i++) {

      const channel = Math.random() < 0.5 ? 'NO' : 'SE'
      const obj = {
          id: `${randomId()}-1-2`,
          date: randomFutureDate(),
          customer: randomFullName(),
          channel: channel,
          name: safariExperiences[Math.floor(Math.random() * safariExperiences.length)],
          seen: randomBoolean(),
          seller: randomFullName(),
          total: randomFormattedNumber(channel),
          status: ['open', 'unopened', 'unfulfilled', 'fulfilled', 'closed'][Math.floor(Math.random() * 5)],
          paymentStatus: ['unpaid', 'paid', 'no-invoice'][Math.floor(Math.random() * 3)],
      };
      objectsArray.push(obj);
  }

  return objectsArray
}

export function getRandomCustomers(){
  // Generer array med objekter
  const numberOfObjects = 16; // Endre dette tallet for å få mer enn 20 objekter
  const objectsArray = [];

  for (let i = 0; i < numberOfObjects; i++) {

      const channel = Math.random() < 0.5 ? 'NO' : 'SE'
      const obj = {
          id: `${randomId()}`,
          name: randomFullName(),
          latest_activity: randomPastDate(),
          location: channel === 'NO' ? 'Norge' : 'Sverige',
          inquiries: Math.floor(Math.random() * 3),
          orders: Math.floor(Math.random() * 3),
          spender: randomBoolean(),
          amount_spent: randomFormattedNumber(channel),
      };
      objectsArray.push(obj);
  }

  return objectsArray
}


const messages = [
  { id: 1, from: 101, message: 'Vi håper tilbudet faller i smak. Har du spørsmål er det bare å svare her eller ta kontakt med meg!', createdon: '2023-09-17 22:32' },
  { id: 2, from: 342, message: 'Dette ser bra ut, men kan dere endre reisetidspunktet?', createdon: '2023-09-19 11:18' }
];



// Funksjon for å generere tilfeldig meldingstekst fra en liste av alternativer
function generateRandomMessage() {
	const messageList = [
        'Hei, hvordan har du det?',
        'Dette er en tilfeldig melding.',
        'Hva tenker du om dette tilbudet?',
        'Har du noen spørsmål?',
        'Jeg ønsker deg en fin dag!',
        'Takk for at du tok kontakt.',
        'La oss diskutere dette videre.',
        'Er det noe annet du lurer på?',
        'God morgen!',
        'Hva er dine tanker om dette?',
        'Kan du sende meg mer informasjon?',
        'Vi ser frem til å høre fra deg.',
        'Hvordan går det med deg i dag?',
        'Dette er viktig, vennligst svar så snart som mulig.',
        'Er du interessert i våre tjenester?',
        'Hva er din tidsplan?',
        'Hva er din preferanse?',
        'Takk for at du er en lojal kunde!',
        'Har du hørt om våre nye tilbud?',
        'Dette er en automatisert melding.',
        'Vi setter pris på din støtte.',
        'Er det noe vi kan hjelpe deg med?',
        'Hvordan var din helg?',
        'Hvordan går det på jobben?',
        'Vær oppmerksom på våre kommende arrangementer.',
        'Hvordan kan vi forbedre våre tjenester?',
        'Takk for din bestilling!',
        'Husk å sjekke din e-post for oppdateringer.',
        'Er det noe spesielt du leter etter?',
        'Hvordan kan vi gjøre din opplevelse bedre?',
        'Din tilbakemelding er viktig for oss.',
        'Vi ønsker å gi deg mer informasjon om vårt siste prosjekt. Prosjektet vårt er et spennende initiativ som har som mål å revolusjonere måten vi jobber på i denne bransjen. Vi har investert mye tid og ressurser i å utvikle dette prosjektet, og vi er overbevist om at det vil ha en positiv innvirkning på virksomheten din. Vi ser frem til å diskutere dette nærmere med deg og høre dine tanker og ideer. Vennligst la oss vite når du er tilgjengelig for en samtale eller møte, slik at vi kan koordinere planene våre. Takk for din interesse og støtte!',
        'Hei! Vi ønsker å informere deg om en viktig oppdatering i tjenestene våre. Vi har nettopp lansert en ny funksjon som gir deg enda flere muligheter til å tilpasse opplevelsen din hos oss. Denne funksjonen gir deg mer kontroll over hvordan du bruker tjenestene våre, og vi er sikre på at den vil forbedre din totalopplevelse. Vi ønsker å høre dine tilbakemeldinger om denne nye funksjonen og hvordan den påvirker din bruk av tjenestene våre. Ikke nøl med å kontakte oss hvis du har spørsmål eller trenger hjelp med å bruke den nye funksjonen. Vi setter pris på din trofasthet som kunde, og vi ser frem til å fortsette å forbedre tjenestene våre for deg.',
        'Kjære kunde, vi ønsker å takke deg for din støtte og lojalitet gjennom årene. Det er kunder som deg som driver oss til å fortsette å levere førsteklasses tjenester. Vi setter pris på din tillit til oss og er forpliktet til å fortsette å møte dine behov. Som en spesiell gest ønsker vi å tilby deg en eksklusiv rabatt på våre produkter og tjenester. Denne rabatten er vår måte å si takk på for at du er en verdifull kunde. Vi håper at denne rabatten vil gjøre din opplevelse enda bedre. Ikke nøl med å kontakte oss hvis du ønsker mer informasjon om denne spesielle tilbudet. Igjen, takk for din støtte, og vi ser frem til å betjene deg i fremtiden.',
        'Hei, vi har mottatt din henvendelse angående våre tjenester, og vi vil gjerne svare på dine spørsmål og gi deg all nødvendig informasjon. Vårt team er her for å hjelpe deg med alle dine behov og bekymringer. Vi er stolte av å tilby førsteklasses kundeservice og vil gjøre alt vi kan for å sikre at du får den beste opplevelsen med oss. Hvis du har ytterligere spørsmål eller trenger mer detaljert informasjon, vennligst ikke nøl med å kontakte oss. Vi ser frem til å betjene deg og ønsker deg en flott dag!'
    ];
  const randomIndex = Math.floor(Math.random() * messageList.length);
  return messageList[randomIndex];
}

// Funksjon for å generere tilfeldig dato innenfor en viss tidsramme
function getRandomDate() {
  const start = new Date('2023-09-17 00:00');
  const end = new Date('2023-09-23 23:59');
  const randomDate = new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
  return randomDate.toISOString().slice(0, 19).replace('T', ' ');
}

// Funksjon for å generere tilfeldig avsender-ID
function getRandomFrom() {
  return Math.floor(Math.random() * 500);
}

export function generateRandomConversation(length){
  const messages = []

  const users = [
    {
		id: getRandomFrom(),
		fullname: randomFullName(),
		phone: '+47 99999999',
		me: true,
    },{
		id: getRandomFrom(),
		fullname: randomFullName(),
		phone: '+47 99999999',
		me: false
    }]
  	for (let i = 0; i <= length; i++) {
    	messages.push({ 
			id: i, 
			from: users[Math.floor(Math.random() * users.length)].id, 
			message: generateRandomMessage(), 
			createdon: getRandomDate() 
		});
  	}

  return { users, messages }

}

export function getRandomOrders(){
	// Generer array med objekter
	const numberOfObjects = 12; // Endre dette tallet for å få mer enn 20 objekter
	const objectsArray = [];
	

	for (let i = 0; i < numberOfObjects; i++) {
		const expenseMax = Math.floor(Math.random() * (6 - 3 + 1) + 3)
		const channel = Math.random() < 0.5 ? 'NO' : 'SE'
		const obj = {
			id: `${randomId()}-1-2`,
			orderdate: randomFutureDate(),
			departure: randomFutureDate(),
			customer: randomFullName(),
			destination: generateRandomCountries(),
			channel: channel,
			expenses: {
				min: Math.floor(Math.random() * (expenseMax - 1 + 1) + 1),
				max: expenseMax
			},
			seller: randomFullName(),
			pax: Math.floor(Math.random() * (20 - 1 + 1) + 1),
			price: randomFormattedNumber(channel),
			profit: randomFormattedNumber(channel),
		};
		objectsArray.push(obj);
	}
  
	return objectsArray
  }

  function generateRandomCountries() {
    const countries = [
        'Tanzania',
        'Zanzibar',
        'Sør-Afrika',
        'Kenya',
        'Kilimanjaro',
        'Uganda',
        'Botswana',
        'Namibia',
        'Malawi',
        'Zimbabwe',
        'Zambia',
        'Rwanda'
    ];

    // Velg tilfeldig antall verdier mellom 1 og 2
    const numberOfValues = Math.floor(Math.random() * 2) + 1;

    // Opprett en variabel for de valgte landene
    const selectedCountries = [];

    // Plukk tilfeldige land fra den opprinnelige listen uten duplikater
    while (selectedCountries.length < numberOfValues) {
        const randomIndex = Math.floor(Math.random() * countries.length);
        const selectedCountry = countries[randomIndex];

        if (!selectedCountries.includes(selectedCountry)) {
            selectedCountries.push(selectedCountry);
        }
    }

    return selectedCountries;
}

export function getPreviousMonths() {
    const today = new Date();
    const previousMonths = [];

    for (let i = 0; i < 12; i++) {
        let month = today.getMonth() - i;
        let year = today.getFullYear();

        if (month < 0) {
            month += 12;
            year -= 1;
        }

        const date = new Date(year, month, 1);

        const isThisYear = year === today.getFullYear();

        previousMonths.push({
            year,
            month: date.toLocaleString('default', { month: 'long' }),
            thisYear: isThisYear,
			revenue: randomLargeSum(),
			previousyear: randomLargeSum(),
			channel: Math.random() < 0.5 ? 'NO' : 'SE'
        });
    }

    return previousMonths;
}

export function getRandomEmployeeSales() {
    
	const numberOfObjects = 4; // Endre dette tallet for å få mer enn 20 objekter
	const objectsArray = [];
	

	for (let i = 0; i < numberOfObjects; i++) {
		const channel = Math.random() < 0.5 ? 'NO' : 'SE'
		const obj = {
			employee: randomFullName(),
			revenue: randomLargeSum(),
			previousyear: randomLargeSum(),
			channel: channel
		};
		objectsArray.push(obj);
	}
  
	return objectsArray
}