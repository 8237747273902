import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ContextMenu, { ContextMenuItem } from "../components/ContextMenu";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import PageLoading from "../components/PageLoading";
import { ago, channelCurrency, drawProfileBubble, drawStatus, formatDate, getOrdinal, status, statusBubble } from "../components/Functions";
import moment from "moment";
import Modal from "../components/Modal";
import { useFormik } from "formik";
import * as yup from 'yup';
import Input from "../components/Input";
import FormikInput from "../components/FormikInput";
import { useNavigate, useParams } from "react-router";
import { AuthContext } from "../providers/AuthProvider";
import { toast } from "react-toastify";
import { confirm } from "../components/Confirmation";
import { Link } from "react-router-dom";
import Rte from "../components/Rte";
import { faTrumpet } from "@fortawesome/pro-thin-svg-icons";
import FileUpload from "../components/FileUpload";
import InlineChat from "../components/InlineChat";

const Context = createContext()

export const Provider = ({children}) => {
    const { apiCall } = useContext(AuthContext)
    const params = useParams()
    const [data, setData] = useState(null)

    useEffect(() => {
        getDetails()
    }, [params])

    const getDetails = async () => {
        const d = await apiCall({action: 'getOfferDetails', id: params.id})
        if(d.status === 1){
            setData(d.data)
        }else{
            status(d)
        }
    }
    
    return <Context.Provider value={{
        data,
        reload: () => getDetails(),
    }}>{data ? children : <PageLoading />}</Context.Provider>
}

export default function Offer(){
    return (
        <Provider>
            <Main />
        </Provider>
    )
}

function Main(){
    const { data, reload } = useContext(Context)
    const { apiCall, sellers } = useContext(AuthContext)

    const [iframeKey, setIframeKey] = useState(0)
    const iframeRef = useRef()

    useEffect(() => {
        getUnreadMessages()
    }, [data])

    const reloadData = (e) => {
        e.preventDefault()
        reload()
    }

    const [unreadMessages, setUnreadMessages] = useState(0)
    
    const navigate = useNavigate()

    const [formValid, setFormValid] = useState(false)

    let schema = yup.object().shape({
        //title:yup.string().required("A title is required"),
        //adults: yup.number().required('Number larger than 0'),
        //youth: yup.number().required('Number larger than 0'),
        //children: yup.number().required('Number larger than 0'),
        //departure: yup.string().required('Departure is required'),
        //price: yup.string().required('Price is required'),
        //expiration_date: yup.string().required('Expiration date is required'),
        //message: yup.string().required('A message is required')
    });

    var initialValues = {
        title: data?.title ?? '',
        adults: data?.people?.adults,
        youth: data?.people?.youth,
        children: data?.people?.children,
        departure: data?.departure,
        price: data?.price ?? 0,
        price_freetext: data?.price_freetext ?? '',
        price_breakdown: data?.price_breakdown ?? '',
        expiration_date: data?.expiration_date,
        comment: data?.comment ?? '',
        message: data?.message ?? '',
        messageAttachments: data?.messageAttachments ?? [],
        flight_information_from: data?.flight_information?.from ?? '',
        flight_information_to: data?.flight_information?.to ?? '',
        arrival: data?.flight_information?.arrival,
        arrivalDayAfter: data?.flight_information?.arrivalDayAfter
    }


    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: async (values) => {
            
            const d = await apiCall({
                action: 'editOffer',
                id: data.id,
                ...values
            })
            status(d)
            initialValues = values
        },
    });

    useEffect(() => {
        validateForm()
    }, [formik])

    const editOffer = async () => {
        if(['unopened', 'for-review'].includes(data.status)){
            if(await confirm('Caution!', 'This offer has been sent to the customer, so be cautious not to edit any spesific details. If there are major updates, consider creating a new draft.', {label: 'Save anyway', type: 'success'}, 'No, wait..')){
                saveEditedOffer()
            }
        }else{
            saveEditedOffer()
        }
    }

    const saveEditedOffer = async () => {
        
        const d = await apiCall({
            action: 'editOffer',
            id: data.id,
            ...formik.values
        })
        status(d)
    }

    const duplicateOffer = async () => {
        if(await confirm('Create a new draft', 'By creating a new draft, this draft will be duplicated with the same values.', {label: 'Yes, please!', type: 'success'}, 'No, wait..')){
            const d = await apiCall({
                action: 'newOfferVersion',
                id: data.id
            })
            status(d)
            if(d.status === 1) navigate(`/offers/${d.data}`)
            
        }
    }

    const changeSeller = async (e) => {
        
        const d = await apiCall({action: 'changeAssignmentSeller', assignment: data.assignment_id, user: e.target.value})
        status(d)
    }

    const sendOffer = async () => {
        if(await confirm('Send offer to customer', 'Make sure you saved and have all the important details in place before sending the offer to the customer. The offer can be edited afterward, but the customer may have already opened it in the meantime."', {label: 'Send to customer', type: 'success'})){
            
            const d = await apiCall({
                action: 'sendOffer',
                id: data.id,
                ...formik.values
            })
            status(d)
            if(d.status === 1) reload()
        }
    }

    const deleteOffer = async () => {
        if(await confirm('Delete offer?', 'Are you sure you want to delete this offer?', {label: 'Yes, delete it.', type: 'danger'}, 'Cancel')){
            const d = await apiCall({action: 'deleteOffer', id: data.id})
            status(d)
            if(d.status === 1) navigate('/offers')
        }
    }

    const createBooking = async () => {
        const d = await apiCall({action: 'convertOfferToBooking', id: data.id});
        status(d)
        if(d.status === 1) navigate(`/bookings/${d.data.id}`)
    }

    const flagOffer = async () => {
        const d = await apiCall({action: 'flagOffer', id: data.id});
        status(d)
        if(d.status === 1) reload()
    }

    const notAccepted = async () => {
        const d = await apiCall({action: 'setOfferNotAccepted', id: data.id});
        status(d)
        if(d.status === 1) reload()
    }

    const availableAsTemplate = async () => {
        const d = await apiCall({action: 'setOfferAvailableAsTemplate', id: data.id});
        status(d)
        if(d.status === 1) reload()
    }

    const openOffer = async () => {
        const d = await apiCall({action: 'setOfferOpened', id: data.id});
        status(d)
        if(d.status === 1) reload()
    }

    const acceptOffer = async () => {
        const d = await apiCall({action: 'setOfferAccepted', id: data.id});
        status(d)
        if(d.status === 1) reload()
    }

    const notifyOfferAccept = async () => {
        const d = await apiCall({
            action: 'notifyOfferAccept', 
            id: data.id,
        })
        status(d)
    }
    const getUnreadMessages = async () => {
        const d = await apiCall({
            action: 'getUnreadMessagesBasedOnState', 
            assignment: data.assignment_id,
            state: {
                id: data.id,
                no: data.nr,
                type: 'Offer'
            }
        })

        setUnreadMessages(d)
    }

    const refreshIframe = () => {
        setIframeKey(iframeKey +1)
    }

    const validateForm = () => {
        const values = formik.values
        let bool = true
        if(!values.title || values.title.length === 0) bool = false
        if(!values.departure || values.departure.length === 0) bool = false
        if(!values.expiration_date || values.expiration_date.length === 0) bool = false
        if(!values.message || values.message.length === 0) bool = false
        if(!values.price || values.price.length === 0) bool = false

        setFormValid(bool)

    }

    return (
        <div className="bg-light-grey">
            <div className="page bg-white px-md-5 px-3 py-3 mb-4">
                <div className="row gx-5 mb-5">
                    <div className="col-md mb-4 mb-md-0">
                        <div className="row justify-content-between">
                            <div className="col-auto"><h3>Offer #{data.nr}</h3></div>
                            <div className="col-auto"><FontAwesomeIcon onClick={() => flagOffer()} icon={['fas', 'flag']} className={`pointer message-flag ms-4 ${data.flagged ? 'message-flag-active text-danger' : ''}`} size="lg" /></div>
                        </div>
                        
                        <div className="row gx-2 align-items-center">
                            <div className="col-auto">{moment(data.createdon).format('MMMM DD. YYYY')}</div>
                            <div className="col-auto"><FontAwesomeIcon icon={['fas', 'circle']} size="xs" style={{transform: 'scale(.5)'}} /></div>
                            <div className="col-auto">{
                                sellers ? 
                                    <select class="special-select" onChange={changeSeller} defaultValue={data.seller?.id}>
                                    {  sellers.map((s, k) => <option key={k}  value={s.id}>{s.fullname}</option>) }
                                </select>
                                : data.seller?.fullname
                            }</div>
                        </div>
                        {statusBubble(data.status)}
                        
                    </div>
                    <div className="col-auto">
                        <div className="row gx-2 align-items-center">
                            
                            <div className="col-auto">
                                <button className="btn btn-sm btn-success" onClick={() => formik.submitForm()}>{data.status === 'draft' ? 'Save' : 'Save changes'}</button>
                            </div>
                            
                            { formValid && data.status === 'draft' ?
                                <div className="col-auto">
                                    <button className="btn btn-sm btn-warning" onClick={() => sendOffer()}>Send offer</button>
                                </div> : null
                            }
                            <div className="col-auto">
                                <button className="btn btn-sm btn-grey" onClick={() => duplicateOffer()}>New draft</button>
                            </div>
                            <div className="col-auto">
                                <ContextMenu
                                        buttonEl={<button class="btn btn-sm btn-grey">
                                        <div className="row gx-2">
                                            <div className="col-auto">More actions</div>
                                            <div className="col-auto"><FontAwesomeIcon icon={['far', 'chevron-down']} size="xs" /></div>
                                        </div>
                                    </button>}
                                    >
                                        {data.status !== 'not-accepted' ? 
                                        <>
                                        <ContextMenuItem
                                            label={'Set status: Not accepted'}
                                            icon={['far', 'circle-xmark']}
                                            onClick={() => notAccepted()}
                                        />
                                        <hr style={{margin: 0}} />
                                        </>
                                        : null}
                                        
                                        <ContextMenuItem
                                            label={data.flagged === 1 ? 'Flagged' : 'Flag offer'}
                                            icon={[data.flagged ? 'fas' : 'far', 'flag']}
                                            colorClass={data.flagged ? 'bg-success' : null}
                                            onClick={() => flagOffer()}
                                            style={{'--bs-bg-opacity': '.2'}}
                                        />
                                        <ContextMenuItem
                                            label={'Available as template'}
                                            icon={['far', data?.properties?.template ? 'square-check' : 'square']}
                                            colorClass={data?.properties?.template ? 'bg-success' : null}
                                            onClick={() => availableAsTemplate()}
                                            style={{'--bs-bg-opacity': '.2'}}
                                        />
                                        <hr style={{margin: 0}} />
                                        <ContextMenuItem
                                            label="Create booking on behalf of customer"
                                            icon={['far', 'plane']}
                                            onClick={() => createBooking()}
                                        />
                                        <ContextMenuItem
                                            label="Notify customer: Remind to accept offer"
                                            icon={['far', 'envelope']}
                                            onClick={() => notifyOfferAccept()}
                                        />
                                        <hr style={{margin: 0}} />
                                        <ContextMenuItem 
                                            label="Delete"
                                            icon={['far', 'trash']}
                                            onClick={() => deleteOffer()}
                                            colorClass="text-danger"
                                        />
                                    </ContextMenu>
                                
                            </div>
                        </div>
                    </div>
                </div>
                
                    <div className="row gx-5">
                        <div className="col">
                        <form onSubmit={formik.handleSubmit}>
                            {data.inquiry_message ? 
                        <div className="alert alert-info" role="alert">
                            <FontAwesomeIcon className="me-2" icon={['far', 'message']} />
                            <span style={{whiteSpace: 'pre'}}>{data.inquiry_message}</span>
                        </div> : null }
                            <div class="mb-3">
                                <FormikInput 
                                    name="title"
                                    label="Title"
                                    formik={formik}
                                />
                            </div>
                            <div class="mb-3">
                                <div className="row gx-2">
                                    <div className="col-md-4">
                                        <FormikInput 
                                            name="adults"
                                            label="Adults (16+)"
                                            formik={formik}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <FormikInput 
                                            name="youth"
                                            label="Young adults (12-15)"
                                            type="number"
                                            formik={formik}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <FormikInput 
                                            name="children"
                                            label="Children (0-11)"
                                            type="number"
                                            formik={formik}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="mb-3">
                                <div className="row">
                                    <div className="col-md-7">
                                        <FormikInput 
                                            name="departure"
                                            label="Departure date"
                                            type="date"
                                            formik={formik}
                                        />
                                    </div>
                                    <div className="col">
                                        <FormikInput 
                                            name="price"
                                            label="Total price"
                                            inputClass="sensitive"
                                            formik={formik}
                                            prefix={channelCurrency(data.channel)}
                                            mask={(value) => {
                                                if(value){
                                                    
                                                    value = value.toString().replace(/\D/g, "");
                                                    const newValue = new Intl.NumberFormat('nb-NO').format(value)
                                                    return newValue
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="mb-3">
                                <div className="form-group mb-3"> 
                                    <label className="fw-medium">Price freetext</label>
                                    <Rte 
                                        content={formik.values.price_freetext} 
                                        setContent={(e) => formik.setFieldValue('price_freetext', e)} 
                                    />
                                </div>
                            </div>
                            <div class="mb-3">
                                <div className="form-group mb-3">
                                    <label className="fw-medium">Price breakdown</label>
                                    <Rte 
                                        content={formik.values.price_breakdown} 
                                        setContent={(e) => formik.setFieldValue('price_breakdown', e)} 
                                    />
                                </div>
                            </div>
                            <div class="mb-3">
                                <FormikInput 
                                    name="expiration_date"
                                    label="Expiration date"
                                    type="date"
                                    formik={formik}
                                />
                            </div>
                            
                            <div class="mb-3">
                                <FormikInput 
                                    name="comment"
                                    label="Comment (internal)"
                                    type="textarea"
                                    formik={formik}
                                />
                            </div>
                            <div class="mb-3">
                            <div className="form-group mb-3">
                                <label className="fw-medium">Message to customer</label>
                                    <Rte 
                                        content={formik.values.message} 
                                        setContent={(e) => formik.setFieldValue('message', e)}
                                        linkActive={true}
                                    />
                                    <FileUpload
                                        label="Upload tickets / documents"
                                        files={formik.values.messageAttachments}
                                        setFiles={(e) => formik.setFieldValue('messageAttachments', e)}
                                    />
                                </div>
                            </div>
                            <div className="form-group mb-5">
                                <h5 className="fw-medium mb-3">Flight information</h5>
                                <h6>Departure</h6>
                                <FormikInput 
                                    name="departure"
                                    formik={formik}
                                    type="date"
                                    disabled
                                />
                                <div className="mb-4">
                                    <Rte 
                                        content={formik.values.flight_information_from} 
                                        setContent={(e) => formik.setFieldValue('flight_information_from', e)} 
                                    />
                                </div>
                                <h6>Arrival</h6>
                                <FormikInput 
                                    name="arrival"
                                    formik={formik}
                                    type="date"
                                />
                                <div className="mb-4">
                                    <Rte content={formik.values.flight_information_to} setContent={(e) => formik.setFieldValue('flight_information_to', e)} />
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="arrivalDayAfter" checked={formik.values.arrivalDayAfter} onChange={(e) => formik.setFieldValue('arrivalDayAfter', e.target.checked ? 1 : 0)} />
                                    <label className="form-check-label fs-20" for="arrivalDayAfter"><h6>At arrival day after?</h6></label>
                                </div>
                            </div>
                            </form>
                        </div>
                        <div className="col-md-5">
                            <div className="shadow-sm px-4 py-3 rounded mb-4">
                                <div className="mb-3">
                                    <h6>Customer</h6>
                                    <span className="d-block sensitive">{data.customer?.fullname}</span>
                                    <span className="d-block">{data.customer?.country}</span>
                                    <span className="d-block">{data.customer?.booking_count > 0 ? data.customer?.booking_count : 'No'} bookings</span>
                                </div>
                                <div className="">
                                    <h6>Contact info</h6>
                                    <span className="d-block sensitive"><a href={`tel:${data.customer?.phone}`}>{data.customer?.phone}</a></span>
                                    <span className="d-block sensitive"><a href={`mailto:${data.customer?.email}`}>{data.customer?.email}</a></span>
                                </div>
                            </div>
                            <div className="shadow-sm px-4 py-3 rounded mb-4">
                                <div className="">
                                    <h6>Conversion summary</h6>
                                    <span className="d-block">This is their {data.customer?.assignment_count}{getOrdinal(data.customer?.assignment_count)} inquiry</span>
                                    <span className="d-block">Last seen {data.customer?.lastlogin ? ago(data.customer?.lastlogin) : 'never'}</span>
                                </div>
                            </div>
                            <div className="shadow-sm px-4 py-3 rounded mb-4">
                                <div className="">
                                    <h6>Messages</h6>
                                    { data ? 
                                        <InlineChat
                                            id={data.id}
                                            nr={data.nr}
                                            type={"Offer"}
                                            assignment={data.assignment_id}
                                        /> 
                                     : null}
                                </div>
                            </div>
                        </div>
                    </div>
                    
                
                {data.offer_resource_url.length !== 0 ? 
                <div className="form-group mb-5 pb-5">
                    <div className="row">
                        <div className="col">
                            <label className="fw-medium">Offer preview</label>
                        </div>
                        <div className="col-auto">
                            <a href={data.offer_resource_manager_url} target="_blank">Edit</a>
                        </div>
                    </div>
                    <div className="d-block position-relative" style={{height: 800, border: '1px solid #9f9f9f', background: '#f5f6fa'}}>
                        <div className="row gx-2" style={{position: 'absolute', top: 20, right: 20}}>
                            <div className="col-auto"><button className="btn btn-sm btn-grey"  onClick={() => refreshIframe()}>Refresh</button></div>
                            <div className="col-auto"><a className="btn btn-sm btn-grey" href={`${data.offer_resource_url}?secret=D78xa7sqPw`} target="_blank">View</a></div>
                        </div>
                        
                        <iframe ref={iframeRef} src={`${data.offer_resource_url}?secret=D78xa7sqPw&${iframeKey}`} style={{height: '100%', width: '100%'}}></iframe>
                    </div>
                </div>
                : <p>There was an error generating URL for the offer. Try <a href="#" onClick={(e) => reloadData()} className="text-primary">reloading</a>, or clearing cache in the <a target="_blank" href={data.offer_resource_manager_url} className="text-primary">manager</a></p>}
            </div>
        </div>
    )
}