import { createContext, useContext, useEffect, useState } from "react";
import packageJson from '../../package.json'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import Modal from "../components/Modal";

const Context = createContext()
export default function VersionControl({children}){
    const [showUpdate, setShowUpdate] = useState(false)

    return (
        <Context.Provider value={{
            showUpdate,
            setShowUpdate
        }}>
            <Update />
            {children}
        </Context.Provider>
    )
}


export const VersionLog = () => {
    const { showUpdate, setShowUpdate } = useContext(Context)
    const [changelog, setChangelog] = useState(null)
    const [showModal, setShowModal] = useState(false)
    let storedLastModified = null
    

    useEffect(() => {
        
        const fetchChangelog = async () => {
            // Hent innholdet fra public/versions.json
            const url = process.env.PUBLIC_URL + 'changelog.json';
           
            try {
                const headResponse = await fetch(url, { method: 'HEAD', cache: 'no-store' });
    
                if (headResponse.ok) {
                    const lastModified = headResponse.headers.get('Last-Modified');
                    if (lastModified && lastModified === storedLastModified) {
                        return; // Ingen endringer, avbryt
                    }
                    
                    // Hvis den har blitt endret, last ned den nye versjonen
                    const response = await fetch(url);
                    if(!response.ok) return

                    const json = await response.json();

                    const reversed = [...json.versions].reverse()
                    const final = [...reversed]
                    
                    setChangelog(final);

                    // Lagre den nye lastModified-datoen
                    storedLastModified = lastModified

                    // Kall compareVersions etter at changelog er oppdatert
                    compareVersions(final[0]);
                } else {
                console.error('Error fetching changelog (HEAD request failed):', headResponse.statusText);
                }
            } catch (error) {
                console.error('Error fetching changelog:', error);
            }

        }

        const compareVersions = (logItem) => {
            if (!logItem) return; // Sjekk at det finnes versjoner
      
            const version2 = packageJson.version; // Hent versjonen fra package.json
            const version1 = logItem.version.substr(1); // Bruk den første versjonen fra changelog
      
            const comparisonResult = compareVersionStrings(version1, version2);
            if (comparisonResult > 0 && !showUpdate){
                setShowUpdate({from: version1, to: version2}) 
                console.log('Oppdateringsmelding skal vises', version1, version2)
            }
        }
      
        const compareVersionStrings = (v1, v2) => {
            const v1Parts = v1.split('.').map(Number);
            const v2Parts = v2.split('.').map(Number);
            
            // Sammenlign hver del av versjonsnumrene
            for (let i = 0; i < Math.max(v1Parts.length, v2Parts.length); i++) {
                const part1 = v1Parts[i] || 0; // Hvis det ikke finnes, bruk 0
                const part2 = v2Parts[i] || 0; // Hvis det ikke finnes, bruk 0
              
                if (part1 > part2) return 1; // v1 er større
                if (part1 < part2) return -1; // v2 er større
            }
            
            return 0; // Likhet
        }
    
        // Kjør fetchChangelog for første gang
        fetchChangelog()
    
        // Sett opp et intervall for å kjøre fetchChangelog hvert 30. sekund
        const intervalId = setInterval(fetchChangelog, 30000)
        // Rydde opp intervallet ved komponent avmontering
        return () => clearInterval(intervalId);
    }, []); // Tom array sikrer at dette bare kjører én gang ved montering

    const ChangeLog = ({ index, changelog, date, version}) => {

        return (
            <div className={`${index !== 0 ? 'pt-2 border-top' : ''}`}>
                <div className="row align-items-center gx-1">
                    <div className="col">
                        <div className="row">
                            <div className="col-auto"><small className="montserrat m-0 p-0">{version}</small></div>
                            {packageJson.version === version.substr(1) && <div className="col-auto"><span className="badge bg-success">Current</span></div>}
                        </div>
                    </div>
                    <div className="col-auto">
                        {moment(date).format('DD.MM.YY')} kl. {moment(date).format('HH:mm')}
                    </div>
                </div>
                <ul className="changelog">
                    {(changelog && changelog.length) ? changelog.map((v, k) => <li key={k}>{v}</li>) : <li><i>No changes</i></li>}
                </ul>
            </div>
        )
    }
    
    return (
        <>  

            
                
                    <div className="text-center">
                        <small className="version p-2 d-block pointer" onClick={() => setShowModal(true)}>v{packageJson.version}</small>
                    </div>
            
            
        
            <Modal open={showModal} setOpen={setShowModal} containerStyle={{width: 500}}>
                <h3 className="mb-4">Changelog</h3>
                
                {changelog ? 
                    changelog?.length && changelog?.map((v, k) => {
                        return <ChangeLog key={k} index={k} {...v} />
                    })
                    : 
                    <div className="p-5 d-flex justify-content-center">
                        <div className="text-center">
                            <FontAwesomeIcon icon={['far', 'spinner-third']} spin className="text-success mb-3" size="3x" />
                            <h5>Laster inn...</h5>
                        </div>
                    </div>
                }
                
            </Modal>
        </>
    )
}



export const Update = () => {
    const { showUpdate } = useContext(Context)

    if(!showUpdate) return (<></>)
    
        return (
            <div className="p-3 px-4 bg-warning">
                <div className="row align-items-center">
                    <div className="col">
                        <h6 className="m-0">A newer version of Safariresor Admin has been released! Update to load the new changes</h6>
                    </div>
                    <div className="col-auto">
                        <button onClick={() => window.location.reload()} className="btn btn-success">Update now</button>
                    </div>
                </div>
            </div>
        )
}