import { useContext, useEffect, useState } from "react"
import { AuthContext } from "../providers/AuthProvider"
import { useNavigate } from "react-router";
import DateRangePicker from "../components/DateRangePicker";
import Table from "../components/Table";
import TrendValue from "../components/TrendValue";

export default function Statistics() {
    const { user } = useContext(AuthContext);
    const navigate = useNavigate();

    // If user is not superadmin, go to dashboard
    if (!user.superadmin) navigate("/");

    return (
        <div className="p-md-5 p-3 mb-5">
            <div className="row mt-5">
                <div className="col-md-6">
                    <DestinationPerformance />
                </div>
                <div className="col-md-6 mb-5">
                    <EmployeePerformance />
                </div>
            </div>
        </div>
    )
}

function DestinationPerformance() {
    const { apiCall } = useContext(AuthContext)

    const [data, setData] = useState(null);
    const [filteredData, setFilteredData] = useState(data)
    const [channelFilter, setChannelFilter] = useState('SV')
    const [dateFilter, setDateFilter] = useState(null);
    const [compareFilter, setCompareFilter] = useState(null);

    useEffect(() => {
        const inputs = document.querySelectorAll('.rs-date-range-input');
        inputs.forEach(input => {
            input.setAttribute('size', '1');  // Set the desired size for each input
        });

        getData();
    }, []);

    useEffect(() => {
        if (data) setFilteredData(filterChannel(data))
    }, [data])

    const getData = async () => {
        const d = await apiCall({ action: 'getDestinationPerformance' })
        if (d.status === 1) setData(d.data)
    }

    useEffect(() => {
        let temp;
        if (!data) return;
        temp = filterChannel(data);
        temp = filterDate(temp);

        setFilteredData(temp)

    }, [data, channelFilter, dateFilter]);

    const filterChannel = (data) => {
        return data.filter((a) => a.channel === channelFilter);
    }

    const filterDate = (data, filter = dateFilter) => {
        if (!filter || filter.length !== 2) return data;

        const startDate = new Date(filter[0]);
        const endDate = new Date(filter[1]);

        endDate.setHours(23, 59, 59, 999);

        return data.map(destination => {
            return {
                ...destination,
                orders: destination.orders
                    ? destination.orders.filter(order => {
                        const orderDate = new Date(order.date);
                        return !isNaN(orderDate.getTime()) && orderDate >= startDate && orderDate <= endDate;
                    })
                    : [],
                inquiries: destination.inquiries
                    ? destination.inquiries.filter(inquiry => {
                        const inquiryDate = new Date(inquiry.date);
                        return !isNaN(inquiryDate.getTime()) && inquiryDate >= startDate && inquiryDate <= endDate;
                    })
                    : []
            };
        }).filter(destination => destination.orders.length > 0 || destination.inquiries.length > 0);
    };

    const calculateRevenueChange = (destination) => {
        if (!data || !compareFilter || compareFilter.length !== 2) return null;

        const selectedRevenue = destination.orders ? destination.orders.reduce((sum, order) => sum + order.price, 0) : 0;
        const compareData = filterChannel(filterDate(data, compareFilter));
        const compareDestination = compareData.find(d => d.destination === destination.destination);

        const compareRevenue = compareDestination?.orders ? compareDestination.orders.reduce((sum, order) => sum + order.price, 0) : 0;
        if (compareRevenue === 0) return null;

        const percentageChange = ((selectedRevenue - compareRevenue) / compareRevenue) * 100;
        return {
            percentageChange: percentageChange.toFixed(0),
            compareRevenue: compareRevenue
        };
    };



    const calculateInquiryChange = (destination) => {
        if (!data || !compareFilter || compareFilter.length !== 2) return null;

        const selectedInquiries = destination.inquiries ? destination.inquiries.reduce((sum) => sum + 1, 0) : 0;
        const compareData = filterChannel(filterDate(data, compareFilter));
        const compareDestination = compareData.find(d => d.destination === destination.destination);

        const compareInquiries = compareDestination?.inquiries ? compareDestination.inquiries.reduce((sum) => sum + 1, 0) : 0;
        if (compareInquiries === 0) return null;

        const percentageChange = ((selectedInquiries - compareInquiries) / compareInquiries) * 100;
        return {
            change: percentageChange.toFixed(0),
            compare: compareInquiries
        }
    }

    return (
        <div>
            <h3 className="mb-4">Destination performance</h3>
            <div className="row mb-4 gy-2">
                <div className="col-auto">
                    <DateRangePicker
                        onChange={(value) => {
                            setDateFilter(value);
                            if (value) {
                                const compareStart = new Date(value[0]);
                                const compareEnd = new Date(value[1]);

                                compareStart.setFullYear(compareStart.getFullYear() - 1);
                                compareEnd.setFullYear(compareEnd.getFullYear() - 1);

                                setCompareFilter([compareStart, compareEnd]);
                            }
                        }}
                    />
                </div>
                <div className="col-auto">
                    <DateRangePicker
                        label="Compare to: "
                        value={compareFilter}
                        onChange={(value) => setCompareFilter(value)}
                    />
                </div>
                <div className="col-auto">
                    <div className="small-select">
                        <select onChange={(e) => setChannelFilter(e.target.value)}>
                            <option value="SV">SE</option>
                            <option value="NO">NO</option>
                            <option value="DK">DK</option>
                        </select>
                    </div>
                </div>
            </div>

            <Table
                gridTemplateColumns={["3fr 1fr 1fr 2fr 1fr", "3fr 1fr 1fr 2fr 1fr", "repeat(3, 1fr)"]}
                gridTemplateRows={["1fr", "1fr", "1fr", "1fr"]}
                perPage={10}
                rows={filteredData}
                headers={[
                    { title: 'Destination', key: 'destination', sort: 'string' },
                    {
                        title: 'Orders', key: 'orders', total: false, sort: 'number', totalCalculation: () => {
                            if (!filteredData?.length) return 0;

                            return filteredData.reduce((total, destination) => {
                                return total + (destination.orders ? destination.orders.length : 0);
                            }, 0).toLocaleString('en-US');
                        },
                        sortValue: (e) => e.orders ? e.orders.length : 0
                    },
                    {
                        title: 'Pax', key: 'pax', total: false, sort: 'number', totalCalculation: () => {
                            if (!filteredData?.length) return 0;

                            return filteredData.reduce((total, destination) => {
                                if (destination.orders) {
                                    const pax = destination.orders.reduce((acc, order) => acc + order.pax, 0);
                                    return total + pax;
                                }
                                return total;
                            }, 0).toLocaleString('en-US');
                        },
                        sortValue: (e) => {
                            if (e.orders) {
                                return e.orders.reduce((acc, order) => acc + order.pax, 0);
                            }
                            return 0;
                        }
                    },
                    {
                        title: 'Revenue', key: 'revenue', total: false, sort: 'number', totalCalculation: () => {
                            if (!filteredData?.length) return 0;

                            return filteredData.reduce((total, destination) => {
                                if (destination.orders) {
                                    const revenue = destination.orders.reduce((acc, order) => acc + order.price, 0);
                                    return total + revenue;
                                }
                                return total;
                            }, 0).toLocaleString('en-US');
                        },
                        sortValue: (e) => {
                            if (e.orders) {
                                return e.orders.reduce((acc, order) => acc + order.price, 0);
                            }
                            return 0;
                        }
                    },
                    {
                        title: 'Inquiries', key: 'unquires', total: false, sort: 'number', totalCalculation: () => {
                            if (!filteredData?.length) return 0;

                            return filteredData.reduce((total, destination) => {
                                return total + (destination.inquiries ? destination.inquiries.length : 0);
                            }, 0).toLocaleString('en-US');
                        },
                        sortValue: (e) => e.inquiries ? e.inquiries.length : 0
                    }
                ]}
                columns={[
                    {
                        grid: ["3fr", "3fr", "3fr"],
                        display: ({ destination }) => {
                            return destination
                        }
                    },
                    {
                        grid: ["auto", "auto", "auto"],
                        display: ({ orders }) => {
                            if (orders) {
                                return orders.reduce((acc, object) => {
                                    return acc + 1
                                }, 0).toLocaleString('en-US')
                            } else {
                                return 0
                            }
                        }
                    },
                    {
                        grid: ["auto", "auto", "auto"],
                        display: ({ orders }) => {
                            if (orders) {
                                return orders.reduce((acc, object) => {
                                    return acc + object.pax
                                }, 0).toLocaleString('en-US')
                            } else {
                                return 0;
                            }
                        }
                    },
                    {
                        grid: ["2fr", "2fr", "2fr"],
                        display: (destination) => {
                            if (destination.orders) {
                                const totalRevenue = destination.orders.reduce((acc, order) => acc + order.price, 0);
                                const result = calculateRevenueChange(destination);
                                let change = 0;
                                let compareValue = 0;
                                if (result) {
                                    change = result.percentageChange
                                    compareValue = result.compareRevenue;
                                }

                                return (
                                    <TrendValue
                                        value={totalRevenue}
                                        compareValue={compareValue}
                                        change={change}
                                    />
                                );
                            } else {
                                return 0;
                            }
                        }
                    },
                    {
                        grid: ["auto", "auto", "auto"],
                        display: (destination) => {
                            if (destination.inquiries) {
                                const totalInquires = destination.inquiries.reduce((acc) => acc + 1, 0);
                                const result = calculateInquiryChange(destination);
                                let change = 0;
                                let compareValue = 0;
                                if (result) {
                                    change = result.change;
                                    compareValue = result.compare;
                                }

                                return (
                                    <TrendValue
                                        value={totalInquires}
                                        compareValue={compareValue}
                                        change={change}
                                    />
                                )
                            } else {
                                return 0
                            }
                        }
                    }
                ]}
            />
        </div>
    )
}

function EmployeePerformance() {
    const { apiCall } = useContext(AuthContext)

    const [data, setData] = useState(null);
    const [filteredData, setFilteredData] = useState(data)
    const [channelFilter, setChannelFilter] = useState('all')
    const [dateFilter, setDateFilter] = useState(() => {
        const today = new Date();
        const startOfYear = new Date(today.getFullYear(), 0, 1);
        return [startOfYear, today];
    });

    const [compareFilter, setCompareFilter] = useState(() => {
        const today = new Date();
        const startOfYear = new Date(today.getFullYear(), 0, 1);
        const lastYearStart = new Date(startOfYear);
        const lastYearEnd = new Date(today);

        lastYearStart.setFullYear(lastYearStart.getFullYear() - 1);
        lastYearEnd.setFullYear(lastYearEnd.getFullYear() - 1);

        return [lastYearStart, lastYearEnd];
    });

    useEffect(() => {
        const inputs = document.querySelectorAll('.rs-date-range-input');
        inputs.forEach(input => {
            input.setAttribute('size', '1');  // Set the desired size for each input
        });

        getData();
    }, []);

    useEffect(() => {
        if (data) setFilteredData(filterChannel(data))
    }, [data])

    const getData = async () => {
        /* const response = await fetch('https://api.safariresor.se/unit-testing');
        const json = await response.json();
        setData(json); */

        const d = await apiCall({ action: 'getEmployeePerformance' })
        if (d.status === 1) setData(d.data)
    }

    useEffect(() => {
        let temp;
        if (!data) return;
        temp = filterChannel(data);
        temp = filterDate(temp);

        setFilteredData(temp)

    }, [data, channelFilter, dateFilter]);

    const filterChannel = (data) => {
        if (channelFilter === 'all') return data;

        return data.filter((item) =>
            item.bookings.some(b => b.channel === channelFilter) ||
            item.offers.some(o => o.channel === channelFilter)
        );
    };


    const filterDate = (data, filter = dateFilter) => {
        if (!filter || filter.length !== 2) return data;

        const startDate = new Date(filter[0]);
        const endDate = new Date(filter[1]);

        endDate.setHours(23, 59, 59, 999);

        return data.map(employee => {
            return {
                ...employee,
                bookings: employee.bookings
                    ? employee.bookings.filter(booking => {
                        const departureDate = new Date(booking.date);
                        return !isNaN(departureDate.getTime()) && departureDate >= startDate && departureDate <= endDate;
                    }) : [],
                offers: employee.offers
                    ? employee.offers.filter(offer => {
                        const offerDate = new Date(offer.date);
                        return !isNaN(offerDate.getTime()) && offerDate >= startDate && offerDate <= endDate;
                    }) : []
            };
        }).filter(employee => employee.bookings.length > 0 || employee.offers.length > 0);
    };

    const calculateRevenueChange = (employee) => {
        if (!data || !compareFilter || compareFilter.length !== 2) return null;

        const selectedRevenue = employee.bookings ? employee.bookings.reduce((sum, booking) => sum + booking.price, 0) : 0;
        const compareData = filterChannel(filterDate(data, compareFilter));
        const compareEmployee = compareData.find(e => e.name === employee.name);

        const compareRevenue = compareEmployee?.bookings ? compareEmployee.bookings.reduce((sum, booking) => sum + booking.price, 0) : 0;
        if (compareRevenue === 0) return null;

        const percentageChange = ((selectedRevenue - compareRevenue) / compareRevenue) * 100;
        return { change: percentageChange.toFixed(0), compare: compareRevenue };
    }

    const calculateOffersChange = (employee) => {
        if (!data || !compareFilter || compareFilter.length !== 2) return null;

        const selectedOffers = employee.offers ? employee.offers.length : 0;
        const compareData = filterChannel(filterDate(data, compareFilter));
        const compareEmployee = compareData.find(e => e.name === employee.name);

        const compareOffers = compareEmployee?.offers ? compareEmployee.offers.length : 0;
        if (compareOffers === 0) return null;

        const percentageChange = ((selectedOffers - compareOffers) / compareOffers) * 100;
        return { change: percentageChange.toFixed(0), compare: compareOffers };
    };

    const calculateProfitChange = (employee) => {
        if (!data || !compareFilter || compareFilter.length !== 2) return null;

        const selectedProfit = employee.bookings ? employee.bookings.reduce((sum, booking) => sum + (booking.price - booking.cost), 0) : 0;
        const compareData = filterChannel(filterDate(data, compareFilter));
        const compareEmployee = compareData.find(e => e.name === employee.name);

        const compareProfit = compareEmployee?.bookings ? compareEmployee.bookings.reduce((sum, booking) => sum + (booking.price - booking.cost), 0) : 0;
        if (compareProfit === 0) return null;

        const percentageChange = ((selectedProfit - compareProfit) / compareProfit) * 100;
        return { change: percentageChange.toFixed(0), compare: compareProfit };
    };


    return (
        <div>
            <h3 className="mb-4">Employee performance</h3>
            <div className="row mb-4 gy-2">
                <div className="col-auto">
                    <DateRangePicker
                        value={dateFilter}
                        onChange={(value) => {
                            setDateFilter(value);
                            if (value) {
                                const compareStart = new Date(value[0]);
                                const compareEnd = new Date(value[1]);

                                compareStart.setFullYear(compareStart.getFullYear() - 1);
                                compareEnd.setFullYear(compareEnd.getFullYear() - 1);

                                setCompareFilter([compareStart, compareEnd]);
                            }
                        }}
                    />
                </div>
                <div className="col-auto">
                    <DateRangePicker
                        label="Compare to: "
                        value={compareFilter}
                        onChange={(value) => setCompareFilter(value)}
                    />
                </div>
                <div className="col-auto">
                    <div className="small-select">
                        <select onChange={(e) => setChannelFilter(e.target.value)}>
                            <option value="all">All channels</option>
                            <option value="SE">SE</option>
                            <option value="NO">NO</option>
                            <option value="DK">DK</option>
                        </select>
                    </div>
                </div>
            </div>

            <Table
                gridTemplateColumns={["1fr 1fr 1fr 1fr", "1fr 1fr 1fr 1fr", "repeat(3, 1fr)"]}
                gridTemplateRows={["1fr", "1fr", "1fr", "1fr"]}
                perPage={10}
                rows={filteredData}
                headers={[
                    { title: 'Employee', key: 'name', sort: 'string' },
                    {
                        title: 'Revenue', key: 'price', sort: 'number', sortValue: (e) => {
                            if (e.bookings) {
                                return e.bookings.reduce((accumulator, object) => {
                                    return accumulator + (channelFilter === 'all' || object.channel === channelFilter ? object.price : 0)
                                }, 0)
                            } else {
                                return 0
                            }
                        }
                    },
                    {
                        title: 'Offers sent', key: 'offers', sort: 'number',
                        sortValue: (e) => e.offers ? e.offers.length : 0
                    },
                    {
                        title: 'Profit', key: 'profit', sort: 'number', sortValue: (e) => {
                            if (e.bookings) {
                                const revenue = e.bookings.reduce((acc, object) => {
                                    return acc + (channelFilter === 'all' || object.channel === channelFilter ? object.price : 0)
                                }, 0);

                                const costs = e.bookings.reduce((acc, object) => {
                                    return acc + (channelFilter === 'all' || object.channel === channelFilter ? object.cost : 0)
                                }, 0);

                                return revenue - costs;
                            } else {
                                return 0;
                            }
                        }
                    }
                ]}
                columns={[
                    {
                        grid: ["auto", "auto", "auto"],
                        display: ({ name }) => {
                            return <div>{name}</div>
                        }

                    },
                    {
                        grid: ["auto", "auto", "auto"],
                        display: (employee) => {
                            if (employee.bookings) {
                                const totalRevenue = employee.bookings.reduce((acc, booking) => acc + (channelFilter === 'all' || booking.channel === channelFilter ? booking.price : 0), 0);
                                const result = calculateRevenueChange(employee);
                                let change = 0;
                                let compareValue = 0;
                                if (result) {
                                    change = result.change;
                                    compareValue = result.compare;
                                }

                                return (
                                    <TrendValue
                                        value={totalRevenue}
                                        compareValue={compareValue}
                                        change={change}
                                    />
                                )
                            } else {
                                return 0;
                            }
                        }
                    },
                    {
                        grid: ["auto", "auto", "auto"],
                        display: (employee) => {
                            if (employee.offers) {
                                const totalOffers = employee.offers.reduce((acc, offer) => acc + (channelFilter === 'all' || offer.channel === channelFilter ? 1 : 0), 0);
                                const result = calculateOffersChange(employee);
                                let change = 0;
                                let compareValue = 0;
                                if (result) {
                                    change = result.change;
                                    compareValue = result.compare;
                                }

                                return (
                                    <TrendValue
                                        value={totalOffers}
                                        compareValue={compareValue}
                                        change={change}
                                    />
                                )
                            } else {
                                return 0;
                            }
                        }
                    },
                    {
                        grid: ["auto", "auto", "auto"],
                        display: (employee) => {
                            if (employee.bookings) {
                                const totalProfit = employee.bookings.reduce((acc, booking) =>
                                    acc + (channelFilter === 'all' || booking.channel === channelFilter ? booking.price - booking.cost : 0), 0
                                );

                                const result = calculateProfitChange(employee);
                                let change = 0;
                                let compareValue = 0;
                                if (result) {
                                    change = result.change;
                                    compareValue = result.compare;
                                }

                                return (
                                    <TrendValue
                                        value={totalProfit}
                                        compareValue={compareValue}
                                        change={change}
                                    />
                                )
                            } else {
                                return 0;
                            }
                        }
                    }
                ]}
            />
        </div>
    )
}