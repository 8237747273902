import moment from 'moment'
import Table from '../components/Table'
import { useNavigate } from 'react-router'
import { statusBubble } from '../components/Functions'
import { useContext, useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AuthContext } from '../providers/AuthProvider'
import DateRangePicker from '../components/DateRangePicker'

export default function Inquiries() {
    const navigate = useNavigate()

    const { apiCall } = useContext(AuthContext)

    const [filter, setFilter] = useState('all')
    const [dateFilter, setDateFilter] = useState(null);
    const [data, setData] = useState(null)
    const [filteredData, setFilteredData] = useState(null)
    const [toggleFilters, setToggleFilter] = useState(false)
    const [channelFilter, setChannelFilter] = useState('all')
    const [search, setSearch] = useState('')

    const searchRef = useRef()
    const exportRef = useRef()

    useEffect(() => {
        if (toggleFilters && searchRef && searchRef.current) searchRef.current.focus()
    }, [toggleFilters])

    useEffect(() => {
        //Henter inn alle inquiries
        getInquiries()
    }, [])

    useEffect(() => {
        if (data) setFilteredData(data)
    }, [data])

    const getInquiries = async () => {
        const d = await apiCall({ action: 'getAllInquiries' })
        if (d.status === 1) setData(d.data)

    }

    useEffect(() => {
        var temp

        temp = filterData(data)
        temp = filterChannel(temp)
        temp = filterDate(temp);

        if (search.length) {
            var condition = search.toLowerCase()
            const searchFilter = temp.filter(el => {
                let bool = false
                Object.keys(el).forEach((obj, key) => {
                    if (!el[obj]) return true
                    if (typeof el[obj] === 'string' && el[obj].toLowerCase().includes(condition)) bool = true
                    if (typeof el[obj] === 'object') {
                        Object.keys(el[obj]).forEach((obj2) => {
                            if (typeof el[obj][obj2] === 'string' && el[obj][obj2].toLowerCase().includes(condition)) bool = true
                        })
                    }
                })
                return bool
            })
            temp = searchFilter
        }

        setFilteredData(temp)
    }, [filter, search, channelFilter, dateFilter])

    const filterChannel = (data) => {
        if (channelFilter === 'all') return data
        return data.filter((a) => a.channel === channelFilter)
    }

    const filterDate = (data) => {
        if (!dateFilter || dateFilter.length !== 2) return data;
    
        const startDate = new Date(dateFilter[0]).getTime();
        const endDate = new Date(dateFilter[1]);

        endDate.setHours(23, 59, 59, 999);
        const endDateTime = endDate.getTime();
    
        return data.filter((item) => {
            const itemDate = new Date(item.createdon).getTime();
            return itemDate >= startDate && itemDate <= endDateTime;
        });
    };

    const filterData = (data) => {
        if (filter === 'all') return data
        return data.filter((a) => a.status === filter)
    }

    const FilterButton = ({ label, value }) => {
        return <div className="col-auto"><button className={`toggle-button ${filter === value ? 'active' : ''}`} onClick={() => setFilter(value)}>{label}</button></div>
    }

    return (
        <div className="p-md-5 p-3">
            <div className="row">
                <div className="col">
                    <h3 className='mb-4'>Inquiries</h3>
                </div>
                <div className="col-auto">
                    <button ref={exportRef} className='btn btn-sm btn-grey'>Export</button>
                </div>
            </div>
            <div className="row">
                <div className="col-auto">
                    <div className="small-select mb-3">
                        <select onChange={(e) => setChannelFilter(e.target.value)}>
                            <option value="all">All channels</option>
                            <option value="SE">SE</option>
                            <option value="NO">NO</option>
                            <option value="DK">DK</option>
                        </select>
                    </div>
                </div>
                <div className="col-auto">
                    <DateRangePicker
                        onChange={(value) => setDateFilter(value)}
                    />
                </div>
            </div>
            <Table
                exportRef={exportRef}
                gridTemplateColumns={["1fr 2fr 3fr 2fr 3fr 1fr", "1fr 2fr 3fr 2fr 3fr 1fr", "1fr 2fr 2fr"]}
                gridTemplateRows={["1fr", "1fr", "2fr"]}
                perPage={10}
                rows={filteredData}
                showResult
                unit="inquiries"
                filters={
                    <div className='py-2 px-3'>
                        <div className="row">
                            <div className="col">
                                <div className="row gx-2">
                                    {
                                        [
                                            { label: 'All', value: 'all' },
                                            { label: 'Unopened', value: 'unopened' },
                                            { label: 'Opened', value: 'opened' },
                                            { label: 'Offer sent', value: 'offer-sent' },
                                        ].map((item, key) => <FilterButton key={key} {...item} />)
                                    }
                                </div>
                            </div>
                            <div className="col-auto">
                                <button className="filter-button" onClick={() => setToggleFilter(!toggleFilters)}>
                                    <div className="row gx-1">
                                        <div className="col-auto"><FontAwesomeIcon icon={['far', 'magnifying-glass']} /></div>
                                    </div>
                                </button>
                            </div>
                        </div>
                        {toggleFilters ?
                            <div className='mt-2'>
                                <input ref={searchRef} type='text' className='form-control' placeholder='Search' onChange={(e) => setSearch(e.target.value)} value={search} />
                            </div> : null}
                    </div>
                }
                headers={[
                    { title: 'Inquiry', key: 'id', sort: 'number' },
                    { title: 'Date', key: 'date', sort: 'string' },
                    { title: 'Customer', key: 'customer', sort: 'string', sortValue: (e) => e.customer && e.customer.fullname ? e.customer.fullname : 'ZZZ' },
                    { title: 'Channel', key: 'channel', sort: 'string' },
                    { title: 'Trip', key: 'trip', sort: 'string', sortValue: (e) => e.trip && e.trip.title ? e.trip.title : null },
                    { title: 'Status', key: 'status', sort: 'string' }
                ]}
                defaultSort={{
                    key: 'id',
                    direction: 'DESC'
                }}
                onRowClick={(e) => {
                    navigate(`${e.id}`)
                }}
                onCtrlClick={({ id }) => window.open(`${window.location.href}/${id}`)}
                customSort={(rows) => {
                    if (rows) {
                        const sorted = rows.sort((a, b) => {

                            const a1 = a.id
                            const b1 = b.id

                            return b1 - a1
                        })


                        return sorted
                    }
                }}
                columns={[
                    {
                        grid: ["auto", "auto", "1/1"],
                        display: ({ id }) => {

                            return (
                                <div>#{id}</div>
                            )
                        }

                    },
                    {
                        grid: ["auto", "auto", "2/1"],
                        display: ({ createdon }) => {

                            return (
                                <div className="row gx-1">

                                    <div className="col-auto">
                                        <span className={"bold"}>{moment(createdon).format('DD.MM.YY')}</span>
                                    </div>
                                </div>
                            )
                        }
                    },
                    {
                        grid: ["auto", "auto", "2/2"],
                        display: ({ customer }) => {

                            return customer ? <span className='sensitive'>{customer.fullname}</span> : 'No data'
                        }
                    },
                    {
                        grid: ["auto", "auto", "2/3"],
                        display: ({ channel }) => {
                            return (<div>{channel}</div>)
                        }
                    },
                    {
                        grid: ["auto", "auto", "1/2"],
                        display: ({ trip }) => {
                            return (<div>{trip ? trip.title : 'No data'}</div>)
                        }
                    },
                    {
                        grid: ["auto", "auto", "1/3"],
                        display: ({ status }) => {
                            return (<div>{statusBubble(status)}</div>)
                        }
                    }
                ]}
            />
        </div>
    )
}