import { useContext, useEffect, useReducer, useRef, useState } from "react"
import { ago, channelToCountry, drawProfileBubble, getInitials, status } from "../components/Functions"
import moment from "moment"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Outlet, useNavigate, useOutletContext, useParams } from "react-router"
import { AuthContext } from "../providers/AuthProvider"
import { Link } from "react-router-dom"
import { confirm } from "../components/Confirmation"
import PageLoading from "../components/PageLoading"
import ContextMenu, { ContextMenuItem } from "../components/ContextMenu"
import FileUpload from "../components/FileUpload"
import Rte from "../components/Rte"
import { useCurrentEditor, useEditor } from "@tiptap/react"

export default function Messages(){

    const { apiCall, sellers } = useContext(AuthContext)

    const [initialConversations, setInitialConversation] = useState(null)
    const [filteredConversations, setFilteredConversations] = useState(null)
    const [conversations, setConversations] = useState(null)
    const [selectedConversation, setSelectedConversation] = useState(null)
    const [search, setSearch] = useState('')
    const [sellerFilter, setSellerFilter] = useState('all')
    const [messageFilter, setMessageFilter] = useState('all')

    const params = useParams()
    const navigate = useNavigate()
    
    useEffect(() => {
        getConversations()
    }, [])

    useEffect(() => {
        if(initialConversations){
            initialConversations.sort(function(a,b){
                return b.unread - a.unread
            })
            initialConversations.sort(function(a,b){
                return new Date(b.lastMessageCustomer) - new Date(a.lastMessageCustomer);
            })
            setConversations(initialConversations)
        }
    }, [initialConversations])

    const getConversations = async () => {
        const d = await apiCall({action: 'getConversations'});
        
        if(d.status === 1){
            setInitialConversation(d.data)
        }else{
            status(d)
        }
    }

    

    const changeConversation = (id) => {

        if(params && params.conversation && params.conversation == id) return

        const conv = conversations.filter((i) => i.id === id)

        const index = conversations.findIndex((i) => i.id === id)

        const newConversations = conversations.map(conv => {
            if(conv.id === id){
                return {
                    ...conv,
                    unread: false
                }
            }else{ return conv }
        })

        setConversations(newConversations)
        
        if(conv.length){
            setSelectedConversation(newConversations[index])
            navigate(`${newConversations[index].id}`)
        }
    }

    const ConversationItem = ({id, customer, unread, lastMessage, lastMessageCustomer, seller, state, flagged}) => {

        const [isFlagged, setIsFlagged] = useState(flagged)

        const setFlagged = async () => {
            setIsFlagged(!isFlagged)
            const d = await apiCall({action: 'setConversationFlagged', id: id});
            
            if(d.status !== 1){
                status(d)
                setIsFlagged(!isFlagged)
                
            }else{
                setIsFlagged(d.flagged)
                setInitialConversation(initialConversations.map(el => el.id === id ? { ...el, flagged: d.flagged} : el))
            }
        }

        return (
            <div className={`conversation-item ${unread ? 'unread' : ''} ${params && params.conversation && params.conversation == id ? 'active' : ''} `}>
                <div className="conversation-overlay pointer"  onClick={() => changeConversation(id)}></div>
                <div className="p-3">
                    <div className="row justify-content-between">
                        <div className="col-auto"><h6 className="sensitive">{customer ? customer.fullname : 'No name'}</h6></div>
                        <div className="col-auto"><FontAwesomeIcon onClick={() => setFlagged()} icon={['fas', 'flag']} className={`pointer message-flag ${isFlagged ? 'message-flag-active text-danger' : ''}`} size="lg" /></div>
                    </div>
                    <div className="row justify-content-between">
                        <div className="col-auto">
                            {lastMessageCustomer ? <span>{ago(lastMessageCustomer)}. {unread ? 'Unread' : 'Read'}.</span> : <span>No messages yet</span>}
                        </div>
                        <div className="col-auto">
                            {state ? `#${state.no}` : null} {seller ? <span className="sensitive">({seller.first_name ? seller.first_name : seller.fullname})</span> : ''}
                        </div>
                    </div>
                    
                </div>
            </div>
        )
    }

    useEffect(() => {
        var temp = initialConversations

        temp = filterSeller(temp)
        temp = filterMessages(temp)
        
        if(search.length){
            var condition = search.toLowerCase()
            const searchFilter = temp.filter(el => {
                let bool = false
                Object.keys(el).forEach((obj, key) => {
                    if(!el[obj]) return
                    if(typeof el[obj] === 'string' && el[obj].toLowerCase().includes(condition)) bool = true
                    if(typeof el[obj] === 'object'){
                        Object.keys(el[obj]).forEach((obj2) => {
                            if(typeof el[obj][obj2] === 'string' && el[obj][obj2].toLowerCase().includes(condition)) bool = true
                        })
                    }
                    
                })
                return bool
            })
            temp = searchFilter
        }

        setFilteredConversations(temp)

    }, [search, sellerFilter, messageFilter])

    const filterSeller = (data) => {
        
        if(sellerFilter === 'all') return data 
        return data.filter((a) => a.seller?.id == sellerFilter)
    }

    const filterMessages = (data) => {
        
        if(messageFilter === 'all') return data 
        if(messageFilter === 'unread') return data.filter((m) => m.unread)
        if(messageFilter === 'flagged') return data.filter((m) => m.flagged)
    }

    return (
        <div className="p-md-4 p-3 d-flex flex-column flex-grow-1 flex-basis-0 h-100" style={{overflow: 'hidden'}}>
            <div className="row flex-grow-1 flex-basis-0 h-100" style={{overflow: 'hidden'}}>
                <div className={`col-md-4 d-flex flex-column h-100 message-sidebar ${params.conversation ? 'chat-open' : ''}`} style={{overflow: 'hidden'}}> 
                    <div className="flex-grow-1 d-flex flex-column flex-basis-0 h-100" style={{overflow: 'hidden'}}>
                        <h2>Messages</h2>
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="small-select mb-3">
                                    {
                                        sellers ? 
                                            <select class="special-select w-100" onChange={(e) => setSellerFilter(e.target.value)}>
                                                <option value="all">All sellers</option>
                                                {  sellers.map((s, k) => <option key={k}  value={s.id} className="sensitive">{s.fullname}</option>) }
                                            </select>
                                        : null
                                    }
                                </div>
                            </div>
                            <div className="col">
                            <div className="small-select mb-3">
                                    
                                    <select class="special-select w-100" onChange={(e) => setMessageFilter(e.target.value)}>
                                        <option value="all">All messages</option>
                                        <option value="unread">Unread messages</option>
                                        <option value="flagged">Flagged messages</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="input-group">
                            <div className="input-group-text">
                                <FontAwesomeIcon icon={['far', 'magnifying-glass']} />
                            </div>
                            <input type="text" className="form-control" placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)} />
                            
                        </div>
                        <div className="overscroll d-flex flex-column flex-grow-1 flex-basis-0">
                            {
                                filteredConversations ? filteredConversations.map((c, k) => <ConversationItem key={k} {...c} />) : (conversations ? conversations.map((c, k) => <ConversationItem key={k} {...c} />) : <PageLoading />)
                            }
                        </div>
                    </div>
                </div>
                <div className="col-auto d-md-block d-none">
                    <div className="border h-100"></div>
                </div>
                <div className="col d-flex flex-column h-100" style={{overflow: 'hidden'}}> 
                    <div className="flex-grow-1 d-flex flex-column flex-basis-0 h-100" style={{overflow: 'hidden'}}>
                        <Link to="/messages" className="d-block d-md-none">
                            <div className="row gx-2">
                                <div className="col-auto"><FontAwesomeIcon icon={['fas', 'arrow-left']} /></div>
                                <div className="col-auto">Messages</div>
                            </div>
                        </Link>
                        {
                            params.conversation ? <Outlet context={{selectedConversation, setInitialConversation, initialConversations}}  /> : 
                            <div className="d-flex justify-content-center align-items-center flex-column h-100">
                                <div className="text-center">
                                    <FontAwesomeIcon icon={['fad', 'messages']} size="10x" className="text-success mb-3" />
                                    <h5>Pick a conversation in the sidebar</h5>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export function Chat(){
    
    const {selectedConversation, setInitialConversation, initialConversations} = useOutletContext()
    const { apiCall, user } = useContext(AuthContext)
    const params = useParams()
    const navigate = useNavigate()

    const [sortedMessages, setMessages] = useState(null)
    const [data, setData] = useState(null)
    
    const [message, setMessage] = useState('')
    const [files, setFiles] = useState([])
    
    const [users, setUsers] = useState([user])
    const [state, setState] = useState(null)
    const [allStates, setAllStates] = useState(null)
    const ref = useRef()
    const editorRef = useRef()
    const [disabled, setDisabled] = useState(false)
    const [updater, forceUpdate] = useReducer(x => x + 1, 0);
    
    useEffect(() => {
        setMessages(null)
        setData(null)
        loadConversation()
        
    }, [selectedConversation])

    useEffect(() => {
        if(data){
            setAllStates(data.allStates)
            setState(data.state)
            setUsers([...data.users, user])
            const sorted = data.messages.sort(function(a,b){
                return new Date(a.createdon) - new Date(b.createdon);
            })
            
           setMessages(sorted)
        }
    }, [data])

    const loadConversation = async () => {
        if((selectedConversation && selectedConversation.id) || params.conversation){
            const d = await apiCall({action: 'getConversationMessages', id: selectedConversation ? selectedConversation.id : params.conversation})
            if(d.status === 1){
                setData(d.data)
                console.log(d.data)
            }else{
                status(d)
            }
        }
    }

    useEffect(() => {
        if(ref && ref.current) ref.current.scrollTop = ref.current.scrollHeight
        console.log(sortedMessages)
    }, [sortedMessages])

   useEffect(() => {
        if(ref && ref.current) ref.current.scrollTop = ref.current.scrollHeight
    }, [ref])

    const newMessage = async (e) => {
        e.preventDefault()
        if(editorRef.current){
            
            console.log('editor after', editorRef.current)
        }
        console.log('Message emtpy', message)
        if(!message.length) return
        setDisabled(true)
        const m = {
            id: sortedMessages.length + 1,
            from: user.id,
            message: message,
            state: state,
            createdon: moment().format('YYYY-MM-DD HH:mm'),
        }

        if(files.length){
            m.files = files
        }

        const d = await apiCall({
            action: 'newMessage', 
            assignment: selectedConversation ? selectedConversation.id : params.conversation,
            m
        })
        
        if(d.status === 1){
            setMessages([
                ...sortedMessages,
                m
            ])
            setMessage('')
            setFiles([])
            if(editorRef.current){
            
                editorRef.current.commands.clearContent()
                editorRef.current.commands.setContent()
                console.log('editor after', editorRef.current)
            }
        }else{
            status(d)
        }

        setDisabled(false)
    }

    const setUnread = async () => {
        const d = await apiCall({action: 'setConverstionUnread', assignment: selectedConversation ? selectedConversation.id : params.conversation})
        if(d.status === 1){
            setInitialConversation(initialConversations.map(el => el.id === (selectedConversation ? selectedConversation.id : params.conversation) ? { ...el, unread: true} : el))
            navigate('/messages')
            status(d)
        }else{
            status(d)
        }
    }

    const MessageItem = (props) => {
        const { id, from, message, createdon, state, files } = props
        const messageUser = users.filter((u) => u.id === parseInt(from))[0]
        const me = messageUser?.id === user?.id
        const getUrl = () => {
            switch(state.type){
                case 'Booking': return 'bookings'
                case 'Offer': return 'offers'
                case 'Inquiry': return 'inquiries'
                default: return ''
            }
        }

        const deleteMessage = async () => {
            if(await confirm('Delete message', 'Are you sure you wan\'t to delete this message ?', {label: 'Yes, delete', type: 'danger'})){
                const d = await apiCall({action: 'deleteAssignmentMessage', id: id, assignment: selectedConversation ? selectedConversation.id : params.conversation})
                status(d)
                if(d.status === 1){
                    setMessages(sortedMessages.filter((m) => m.id !== id))
                }
                
            }
        }

        const { downloadAttachment, previewAttachment } = useContext(AuthContext)
        
        const downloadFile = async ({ name, path, type}) => {
            await downloadAttachment({
                name: name,
                path: path,
                type: type
            })
        }

        const FileItem = ({ name, type, size, path }) => {
            return (
                <div className="row gx-2">
                    <div className="col-auto">
                        <FontAwesomeIcon className="pointer text-primary" size="xs" icon={['fas', 'magnifying-glass']} onClick={() => previewAttachment({name, path, type})} />
                    </div>
                    <div className="col-auto">
                        <FontAwesomeIcon className="pointer text-primary" size="xs" icon={['fas', 'paperclip']} onClick={() => downloadFile({name, path, type})} />
                    </div>
                    <div className="col">
                        <small className="pointer text-primary" onClick={() => downloadFile({name, path, type})}>
                            <u style={{textDecoration: 'none'}}>{name}</u>
                        </small>
                    </div>
                </div>
                
            )
        }


        return (
            <div className={`message-container ${me ? 'message-from-me' : ''}`}>
                <div className="message-bubble-wrapper">
                    <small className="hint-text d-block">{moment(createdon).format('MMMM D, YYYY [at] HH:mm')} <FontAwesomeIcon onClick={() => deleteMessage()} icon={['far', 'trash-alt']} title="Delete this message" className="ms-2 text-danger pointer" /></small>
                    {state ? <small><Link className="text-primary" to={`/${getUrl()}/${state.id}`}>#{state.no}</Link> {state.type}</small> : null}
                    <div className="message-bubble">
                        <pre dangerouslySetInnerHTML={{__html: message.trim()}} className="sensitive"></pre>
                        {
                            files && files.length ? files.map((v,k) => <FileItem key={k} {...v} />) : null
                        }
                    </div>
                    <div className="message-user">
                        <div className="row gx-2 align-items-center">
                            <div className="col-auto">
                                <div className="profile-image">
                                    {messageUser ? drawProfileBubble(messageUser) : 'XX'}
                                </div>
                            </div>
                            <div className="col">
                                <span className="d-block sensitive"><a href="#">{messageUser ? messageUser?.fullname : 'Unknown / deleted'}</a></span>
                                <span className="d-block sensitive"><a href="#">{messageUser?.phone}</a></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
   
    if(!sortedMessages) {
        return (
            <div className="d-flex justify-content-center align-items-center flex-column h-100">
                <div className="text-center">
                    <FontAwesomeIcon icon={['fad', 'spinner-third']} spin size="10x" className="text-success mb-3" />
                    <h5>Loading conversation..</h5>
                </div>
            </div>
        )
    }
    
    if(!data) return <PageLoading />
    return (
        <div className="messages-wrapper d-flex flex-column h-100">
            <div className="px-md-5 px-md-3">
            {
                data.customer ?
                <div className="row justify-content-between">
                    <div className="col">
                        <h2>{data?.customer?.fullname}</h2>
                        <p>{channelToCountry(data?.customer?.channel)} &#x2022; Customer since {moment(data?.customer?.createdon).format('YYYY')} &#x2022; Last seen {data?.customer?.lastlogin ? ago(data?.customer?.lastlogin) : 'never'}</p>
                    </div>
                    <div className="col-auto">
                        <ContextMenu
                            buttonEl={<button className="btn btn-sm btn-grey">
                            <div className="row gx-2">
                                <div className="col-auto d-none">More actions</div>
                                <div className="col-auto"><FontAwesomeIcon icon={['far', 'chevron-down']} size="xs" /></div>
                            </div>
                        </button>}
                        >
                            
                            <ContextMenuItem 
                                label="Mark as unread"
                                onClick={() => setUnread()}
                                icon={['fas', 'eye']}
                            />
                            <ContextMenuItem 
                                label="Flag conversation"
                                onClick={() => alert('')}
                                icon={['fas', 'flag']}
                            />
                        </ContextMenu>
                    </div>
                </div>
                : null
            }
            </div>
            <div className="overscroll d-flex flex-column flex-grow-1 flex-basis-0 px-md-5 px-md-3" ref={ref}>
                {
                    sortedMessages.map((item, key) => <MessageItem key={key} {...item} />)
                }
            </div>
            
            <form onSubmit={newMessage} className="px-md-5 px-md-3">
                <select class="special-select" onChange={(e) => setState(allStates[e.target.value])}>
                    {  allStates.map((s, k) => <option selected={s === state} value={k}>#{s.no} {s.type}</option>) }
                </select>
                <div className="form-group mt-2">
                    <div className="row align-items-center">
                        <div className="col">
                            <div className="mb-2">
                                <Rte
                                    updater={updater}
                                    editorRef={editorRef}
                                    linkActive
                                    content={message} 
                                    setContent={(e) => setMessage(e)} 
                                />
                            </div>
                            <div className="mb-md-0 mb-2">
                                <FileUpload
                                    label="Upload tickets / documents"
                                    files={files}
                                    setFiles={(e) => setFiles(e)}
                                />
                            </div>
                        </div>
                        <div className="col-md-auto col-12">
                            <button className="btn btn-primary btn-sm d-md-inline-block d-block w-100" type="submit" disabled={disabled} >
                                <div className="row gx-2 justify-content-center">
                                    <div className="col-auto">Send</div>
                                    {disabled ? <div className="col-auto"><FontAwesomeIcon icon={['far', 'spinner-third']} spin /></div> : null}
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )

}