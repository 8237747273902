import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip, Whisper } from "rsuite";

const TrendValue = (props) => {
    const {
        value,
        compareValue,
        change,
        showZeros = false,
        showIcon = true,
        onDoubleClick
    } = props;

    if (!value) return <div onDoubleClick={onDoubleClick} className="w-100">&nbsp;</div>;

    return (
        <div className="d-flex align-items-centr sensitive" onDoubleClick={onDoubleClick}>
            {value.toLocaleString('en-US')}

            {change !== null && change !== undefined ? (
                compareValue !== null && compareValue !== undefined ? (
                    <Whisper followCursor speaker={
                        <Tooltip>
                            {compareValue.toLocaleString('en-US')}
                        </Tooltip>
                    }>
                        {!showZeros && change !== 0 ? (
                            <div className={`d-flex align-items-center ms-2 fw-semibold small percentage-transform cursor-default ${change > 0 ? 'text-success' : change < 0 ? 'text-danger' : ''}`}>
                                {change > 0 ? <FontAwesomeIcon className='text-success' icon={['fas', 'arrow-up']} /> : change < 0 ? <FontAwesomeIcon className='text-danger' icon={['fas', 'arrow-down']} /> : ''}
                                <span className="ms-1">{change}%</span>
                            </div>
                        ) : null}
                    </Whisper>
                ) : (
                    !showZeros && change !== 0 ? (
                        <div className={`d-flex align-items-center ms-1 fw-semibold percentage-transform ${change > 0 ? 'text-success' : change < 0 ? 'text-danger' : ''}`}>
                            {showIcon ? change > 0 ? <FontAwesomeIcon className='text-success' icon={['fas', 'arrow-up']} /> : change < 0 ? <FontAwesomeIcon className='text-danger' icon={['fas', 'arrow-down']} /> : '' : null}
                            <span className="ms-1 small">{change.toFixed(0)}%</span>
                        </div>
                    ) : null
                )
            ) : null}
        </div>
    )
}

export default TrendValue;