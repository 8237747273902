import { useEffect } from "react";
import { DateRangePicker as RDateRangePicker } from "rsuite";

const DateRangePicker = ({ value, onChange, label }) => {
    const formatDate = (date) => {
        return date.toLocaleDateString("en-US", {
            month: "short",
            day: "numeric",
            year: "numeric"
        });
    };

    const predefinedRanges = [
        {
            label: "This Year",
            value: [
                new Date(new Date().getFullYear(), 0, 1), // Jan 1 of this year
                new Date(new Date().getFullYear(), 11, 31) // Dec 31 of this year
            ],
            placement: 'left'
        },
        {
            label: "Last Year",
            value: [
                new Date(new Date().getFullYear() - 1, 0, 1), // Jan 1 of last year
                new Date(new Date().getFullYear() - 1, 11, 31) // Dec 31 of last year
            ],
            placement: 'left'
        }
    ];

    return (
        <RDateRangePicker
            value={value}
            onChange={(value) => onChange(value)}
            placeholder="Choose period"
            showHeader={true}
            label={label}
            format="yyyy-MM-dd"
            ranges={predefinedRanges}
            renderValue={(value) => {
                if (!value || value.length !== 2) return "";
                return `${formatDate(value[0])} - ${formatDate(value[1])}`;
            }}
            cleanable={true}
        />
    );
};


export default DateRangePicker;