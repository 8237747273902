import moment from 'moment'
import Table from '../components/Table'
import { useNavigate } from 'react-router'
import { channelCurrency, statusBubble } from '../components/Functions'
import { useContext, useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AuthContext } from '../providers/AuthProvider'
import Modal from '../components/Modal'
import FormikInput from '../components/FormikInput'
import { useFormik } from "formik";
import * as yup from 'yup';
import { toast } from 'react-toastify'
import Select from '../components/Select'
import DropDown from '../components/Dropdown'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import ContextMenu, { ContextMenuItem } from '../components/ContextMenu'
import DateRangePicker from '../components/DateRangePicker'

export default function Offers() {
    const { apiCall, sellers } = useContext(AuthContext)
    const navigate = useNavigate()

    const [filter, setFilter] = useState('all')
    const [sellerFilter, setSellerFilter] = useState('all')
    const [flagFilter, setFlagFilter] = useState('all')
    const [dateFilter, setDateFilter] = useState(null);
    const [data, setData] = useState(null)
    const [filteredData, setFilteredData] = useState(data)
    const [toggleFilters, setToggleFilter] = useState(false)
    const [channelFilter, setChannelFilter] = useState('all')
    const [modalOpen, setModalOpen] = useState(false)
    const [search, setSearch] = useState('')

    const searchRef = useRef()
    const exportRef = useRef()

    useEffect(() => {
        if (toggleFilters && searchRef && searchRef.current) searchRef.current.focus()
    }, [toggleFilters])


    useEffect(() => {
        getOffers()
    }, [])

    useEffect(() => {
        if (data) setFilteredData(data)
    }, [data])

    const getOffers = async () => {
        const d = await apiCall({ action: 'getAllOffers' })
        if (d.status === 1) setData(d.data)

    }

    useEffect(() => {
        var temp
        if (!data) return
        temp = filterData(data)
        temp = filterChannel(temp)
        temp = filterSeller(temp)
        temp = filterFlag(temp)
        temp = filterDate(temp);

        if (search.length) {
            var condition = search.toLowerCase()
            const searchFilter = temp.filter(el => {
                let bool = false
                Object.keys(el).forEach((obj, key) => {
                    if (!el[obj] || obj === 'seller') return true

                    if (typeof el[obj] === 'string' && el[obj].toLowerCase().includes(condition)) bool = true
                    if (typeof el[obj] === 'object') {
                        Object.keys(el[obj]).forEach((obj2) => {
                            if (typeof el[obj][obj2] === 'string' && el[obj][obj2].toLowerCase().includes(condition)) bool = true
                        })
                    }
                })
                return bool
            })
            temp = searchFilter
        }

        setFilteredData(temp)
    }, [filter, search, channelFilter, sellerFilter, flagFilter, dateFilter])

    const filterChannel = (data) => {
        if (channelFilter === 'all') return data
        return data.filter((a) => a.channel === channelFilter)
    }

    const filterSeller = (data) => {

        if (sellerFilter === 'all') return data
        return data.filter((a) => a.seller.id == sellerFilter)
    }

    const filterFlag = (data) => {

        if (flagFilter === 'all') return data
        return data.filter((a) => a.flagged == flagFilter)
    }

    const filterDate = (data) => {
        if (!dateFilter || dateFilter.length !== 2) return data;
    
        const startDate = new Date(dateFilter[0]).getTime();
        const endDate = new Date(dateFilter[1]);

        endDate.setHours(23, 59, 59, 999);
        const endDateTime = endDate.getTime();
    
        return data.filter((item) => {
            const itemDate = new Date(item.createdon).getTime();
            return itemDate >= startDate && itemDate <= endDateTime;
        });
    };



    const filterData = (data) => {
        if (filter === 'all') return data.filter((a) => a.status !== 'not-accepted')
        return data.filter((a) => a.status === filter)
    }

    const FilterButton = ({ label, value }) => {
        return <div className="col-auto"><button className={`toggle-button ${filter === value ? 'active' : ''}`} onClick={() => setFilter(value)}>{label}</button></div>
    }

    const createOffer = async (values) => {
        const d = await apiCall({ action: 'createProfile', ...values })

        if (d.status === 1) {

        }
    }

    let schema = yup.object().shape({
        channel: yup.string().required('First name required'),
        country: yup.string().required('First name required'),
        first_name: yup.string().required('First name required'),
        last_name: yup.string().required('Last name required'),
        phone: yup.string().required('Phone required'),
    });


    const formik = useFormik({
        initialValues: {
            email: '',
            country: 'SE',
            channel: 'SE',
            first_name: '',
            last_name: '',
            phone: ''
        },
        validationSchema: schema,
        onSubmit: (values) => {
            createOffer(values)
        },
    });

    return (
        <div className="p-md-5 p-3">
            <div className="row">
                <div className="col">
                    <h3 className='mb-4'>Offers</h3>
                </div>
                <div className="col-auto">
                    <button onClick={() => setModalOpen(true)} className='btn btn-sm btn-primary'>Create offer</button>
                </div>
                <div className="col-auto">
                    <button ref={exportRef} className='btn btn-sm btn-grey'>Export</button>
                </div>
            </div>
            <div className="row">
                <div className="col-auto">
                    <div className="small-select mb-3">
                        <select onChange={(e) => setChannelFilter(e.target.value)}>
                            <option value="all">All channels</option>
                            <option value="SE">SE</option>
                            <option value="NO">NO</option>
                            <option value="DK">DK</option>
                        </select>
                    </div>
                </div>
                <div className="col-auto">
                    <div className="small-select mb-3">
                        {
                            sellers ?
                                <select class="special-select" onChange={(e) => setSellerFilter(e.target.value)}>
                                    <option value="all">All sellers</option>
                                    {sellers.map((s, k) => <option key={k} value={s.id}>{s.fullname}</option>)}
                                </select>
                                : null
                        }
                    </div>
                </div>
                <div className="col-auto">
                    <div className="small-select mb-3">
                        <select onChange={(e) => setFlagFilter(e.target.value)}>
                            <option value="all">All marks</option>
                            <option value="1">Flagged</option>
                            <option value="0">Unflagged</option>
                        </select>
                    </div>
                </div>
                <div className="col-auto">
                    <DateRangePicker
                        onChange={(value) => setDateFilter(value)}
                    />
                </div>
            </div>
            <Table
                exportRef={exportRef}
                gridTemplateColumns={["3fr 2fr 3fr 2fr 1fr", "3fr 2fr 3fr 2fr 1fr", "1fr 2fr 2fr"]}
                gridTemplateRows={["2fr", "2fr", "3fr"]}
                perPage={10}
                rows={filteredData}
                showResult
                unit="offers"
                filters={
                    <div className='py-2 px-3'>
                        <div className="row">
                            <div className="col">
                                <div className="row gx-2">
                                    {
                                        [
                                            { label: 'All', value: 'all' },
                                            { label: 'Unopened', value: 'unopened' },
                                            { label: 'Accepted', value: 'accepted' },
                                            { label: 'Draft', value: 'draft' },
                                            { label: 'For review', value: 'for-review' },
                                            { label: 'Not accepted', value: 'not-accepted' },
                                        ].map((item, key) => <FilterButton key={key} {...item} />)
                                    }
                                </div>
                            </div>
                            <div className="col-auto">
                                <button className="filter-button" onClick={() => setToggleFilter(!toggleFilters)}>
                                    <div className="row gx-1">
                                        <div className="col-auto"><FontAwesomeIcon icon={['far', 'magnifying-glass']} /></div>
                                    </div>
                                </button>
                            </div>
                        </div>
                        {toggleFilters ?
                            <div className='mt-2'>
                                <input ref={searchRef} type='text' className='form-control' placeholder='Search' onChange={(e) => setSearch(e.target.value)} value={search} />
                            </div> : null}
                    </div>
                }
                defaultSort={{
                    key: 'createdon',
                    direction: 'DESC'
                }}
                headers={[

                    { title: 'Offer', key: 'nr', sort: 'number' },
                    { title: 'Latest offer', key: 'createdon', sort: 'date' },
                    { title: 'Departure', key: 'departure', sort: 'date' },
                    { title: 'Customer', key: 'customer', sort: 'string', sortValue: (e) => e.customer && e.customer.fullname ? e.customer.fullname : null },
                    { title: 'Channel', key: 'channel', sort: 'string', justify: 'end' },
                    { title: 'Offer name', key: 'title', sort: 'string' },
                    { title: 'Total', key: 'price', sort: 'number' },
                    { title: 'Seller', key: 'seller', sort: 'string', sortValue: (e) => e ? e.seller.fullname : null },
                    { title: 'Status', key: 'status', sort: 'string' },
                    { title: '', key: 'flagged', sort: 'number', justify: 'end' },
                ]}
                onRowClick={(e) => {
                    navigate(`${e.id}`)
                }}
                onCtrlClick={({ id }) => window.open(`${window.location.href}/${id}`)}
                columns={[

                    {
                        grid: ["1/1", "1/1", "1/1"],
                        display: ({ nr }) => {
                            return <div>#{nr}</div>
                        }

                    },
                    {
                        grid: ["1/2", "1/2", "2/1"],
                        display: ({ createdon }) => {

                            return (
                                <div className="row gx-1">
                                    <div className="col-auto">
                                        <span className={"bold"}>{moment(createdon).format('DD.MM.YY')}</span>
                                    </div>
                                </div>
                            )
                        }
                    },
                    {
                        grid: ["2/2", "2/2", "3/1"],
                        display: ({ departure }) => {

                            return (
                                <div className="row gx-1">
                                    <div className="col-auto">
                                        <span className={"bold"}>{moment(departure).format('DD.MM.YY')}</span>
                                    </div>
                                </div>
                            )
                        }
                    },
                    {
                        grid: ["1/3", "1/3", "1/2"],
                        display: ({ customer }) => {
                            return customer ? <span className='sensitive'>{customer?.fullname}</span> : <i>Unknown / deleted</i>
                        }
                    },
                    {
                        grid: ["2/5", "2/5", "1/3"],
                        display: ({ channel }) => {
                            return (<div>{channel}</div>)
                        }
                    },
                    {
                        grid: ["2/1", "2/1", "2/2"],
                        display: ({ title }) => {
                            return (<div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{title ? title : <i>Not set</i>}</div>)
                        }
                    },
                    {
                        grid: ["2/4", "2/4", "2/3"],
                        display: ({ price, channel }) => {
                            if(price) return (<div>{channelCurrency(channel)} <span className='sensitive'>{new Intl.NumberFormat('nb-NO').format(price)}</span></div>)
                            return (<div><i>Not set</i></div>)
                        }
                    },
                    {
                        grid: ["2/3", "2/3", "3/2"],
                        display: ({ seller }) => {
                            return (<div className='sensitive'>{seller ? seller.fullname : <i>Not set</i>}</div>)
                        }
                    },
                    {
                        grid: ["1/4", "1/4", "3/3"],
                        display: ({ status }) => {
                            return (<div>{statusBubble(status)}</div>)
                        }
                    },
                    {
                        grid: ["1/5", "1/5", "1/3"],
                        display: ({ flagged }) => {
                            return (
                                <div className="row justify-content-end w-100">
                                    <FontAwesomeIcon icon={['fas', 'flag']} className={`inactive-flag ${flagged ? 'message-flag-active text-danger' : ''}`} size="lg" />
                                </div>
                            )
                        }

                    },
                ]}
            />

            <Modal
                open={modalOpen}
                setOpen={setModalOpen}
                containerStyle={{ width: 500 }}
                mounted
            >
                <CreateOffer
                    data={data}
                    setModalOpen={setModalOpen}
                />
            </Modal>
        </div>
    )
}

function CreateOffer({ setModalOpen }) {
    const { apiCall } = useContext(AuthContext)

    const [step, setStep] = useState(1)
    const [loading, setLoading] = useState(false)

    const [trips, setTrips] = useState({
        se: null,
        no: null,
        dk: null
    })

    const [customers, setCustomers] = useState({
        se: null,
        no: null,
        dk: null
    })

    const [offers, setOffers] = useState({
        se: null,
        no: null,
        dk: null
    })

    const nodeRef = useRef()
    const tempRef = useRef()
    const [height, setHeight] = useState(null)


    const navigate = useNavigate()

    const loadTrips = async (channel) => {
        const res = await apiCall({ action: 'getTrips', channel: channel })
        if (res.status === 1) {
            const { data } = res

            setTrips(prevState => ({
                ...prevState,
                [channel]: data.sort((a, b) => a.pagetitle.localeCompare(b.pagetitle)).map(resource => {
                    return {
                        label: resource.pagetitle,
                        value: resource.id,
                        ...resource
                    }
                })
            }))
        }
        return true
    }

    const loadCustomers = async (channel) => {
        const res = await apiCall({ action: 'getCustomersByChannel', channel: channel })
        if (res.status === 1) {
            const { data } = res
            setCustomers(
                [...data].sort((a, b) => a.fullname.localeCompare(b.fullname)).map((c) => {
                    return {
                        label: c.fullname,
                        value: c.id,
                        ...c
                    }
                })
            )
        }

        return true
    }

    const loadOffers = async (channel) => {
        const res = await apiCall({ action: 'getOfferTemplates', channel: channel })
        if (res.status === 1) {
            const { data } = res

            setOffers(prevState => ({
                ...prevState,
                [channel]: [...data].sort((a, b) => a.title.localeCompare(b.title)).map((c) => {
                    return {
                        label: c.title,
                        value: c.id,
                        ...c
                    }
                })
            }))
        }

        return true
    }

    useEffect(() => {
        setTimeout(() => {
            if (tempRef?.current) setHeight(tempRef.current.scrollHeight)
        }, 500)
    }, [step])



    let schema = yup.object().shape({
        customer_id: yup.string().required('Customer is required'),
        baseOn: yup.string().required('BaseOn is required'), // BaseOn må være required
        trip_id: yup.string().nullable().test(
            'is-required-based-on-trip',
            'A trip is required',
            function (value) {
                return this.parent.baseOn !== 'trip' || !!value;
            }
        ),
        offer_id: yup.string().nullable().test(
            'is-required-based-on-offer',
            'An offer is required',
            function (value) {
                return this.parent.baseOn !== 'offer' || !!value;
            }
        ),
        departure: yup.string().required('Departure date is required'),
    });

    const formik = useFormik({
        initialValues: {
            customer_id: '',
            baseOn: 'trip',  // Merk at initialverdien er 'trip'
            trip_id: null,     // Sørg for at det ikke er null, bruk tom streng hvis feltet er tomt
            offer_id: null,    // Samme her, tom streng som standard
            channel: 'SE',
            adults: 0,
            youth: 0,
            children: 0,
            departure: ''
        },
        validationSchema: schema,

        onSubmit: (values) => {
            setLoading(true)
            newOffer(values)
        }
    });

    const newOffer = async (v) => {
        const d = await apiCall({
            action: 'newOfferBasedOn',
            ...v
        })

        if (d.status === 1) {
            toast.success(d.message);
            navigate(`/offers/${d.offer?.id}`)
        } else {
            toast.error(d.message)
        }
    }

    const goNext = async () => {
        if (formik.values.baseOn === 'trip') {
            if (!trips[formik.values.channel]) {
                await loadTrips(formik.values.channel)
            }

            if (!customers[formik.values.channel]) {
                await loadCustomers(formik.values.channel)
            }
            setStep(2)
        }

        if (formik.values.baseOn === 'offer') {
            if (!offers[formik.values.channel]) {
                await loadOffers(formik.values.channel)
            }
            if (!customers[formik.values.channel]) {
                await loadCustomers(formik.values.channel)
            }
            setStep(2)
        }

    }

    const FormStepOne = () => {
        return (
            <div className='' ref={tempRef} id="step-1">
                <div className="form-group py-4">
                    <label className='fw-bold'>Channel</label>
                    <DropDown
                        value={formik.values.channel}
                        onChange={(value) => formik.setFieldValue('channel', value)}
                        options={[
                            { label: 'SE', value: 'SE' },
                            { label: 'NO', value: 'NO' },
                            { label: 'DK', value: 'DK' }
                        ]}
                        placeholder="Select channel"
                    />
                </div>
                <div className="row justify-content-between">
                    <div className="col-auto">
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="baseOn" id="baseOnTrip" value="trip" onChange={(e) => formik.setFieldValue('baseOn', e.target.value)} checked={formik.values.baseOn === 'trip'} />
                            <label className="form-check-label" htmlFor="baseOnTrip">Trip</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="baseOn" id="baseOnOffer" value="offer" onChange={(e) => formik.setFieldValue('baseOn', e.target.value)} checked={formik.values.baseOn === 'offer'} />
                            <label className="form-check-label" htmlFor="baseOnOffer">Offer</label>
                        </div>
                    </div>
                    <div className="col-auto">
                        <button type="button" onClick={() => goNext()} className="btn btn-sm btn-primary">Next</button>
                    </div>
                </div>
            </div>
        )
    }

    const FormStepTwo = () => {
        const currency = channelCurrency(formik.values.channel)

        useEffect(() => {
            setHeight(tempRef.current.offsetHeight)
        }, [])
        return (
            <div className='' ref={tempRef} id="step-trip">
                <div className="py-2">
                    <div className="form-group py-2">
                        <label className='fw-bold'>Send offer to</label>
                        <DropDown
                            formik={formik}
                            name="customer_id"
                            value={formik.values.customer_id}
                            onChange={(value) => formik.setFieldValue('customer_id', value)}
                            options={customers}
                            placeholder="Select customer"
                            optionClass={"px-2 border-bottom"}
                            renderOption={(props) => {
                                const { label, email, channel, spent } = props

                                return (
                                    <div className=''>
                                        <div className='row'>
                                            <div className="col"><span className='fw-medium'>{label}</span></div>
                                            <div className="col-auto">{channel}</div>

                                        </div>
                                        <div className="row justify-content-between">
                                            <div className="col-auto"><small>{email}</small></div>
                                            <div className="col-auto">{spent > 0 && `Spent ${spent} ${currency}`}</div>
                                        </div>

                                    </div>
                                )
                            }}
                        />
                    </div>
                    <div className="form-group py-2">
                        <label className='fw-bold'>Base on</label>
                        {formik.values.baseOn === 'trip' ?
                            <DropDown
                                name="trip_id"
                                formik={formik}
                                value={formik.values.trip_id}
                                onChange={(value) => formik.setFieldValue('trip_id', value)}
                                options={trips[formik.values.channel]}
                                placeholder="Select trip"
                                optionClass={"px-2 border-bottom"}
                                renderOption={({ label, days, experiences, popular, places, price }) => {

                                    return (
                                        <div className=''>
                                            <div className="row justify-content-between">
                                                <div className="col-auto">
                                                    <small>{currency} {parseInt(price).toLocaleString('nb-NO')}</small>
                                                </div>
                                                <div className="col-auto">
                                                    <small>{days} days</small>
                                                </div>
                                            </div>

                                            <div className='row'>
                                                <div className="col"><span className='fw-medium'>{label}</span></div>
                                                {popular === 'populartrip' && <div className="col-auto"><FontAwesomeIcon className='text-warning' icon={['fas', 'star']} /></div>}

                                            </div>
                                            <small>{places}</small>
                                        </div>
                                    )
                                }}
                            />
                            :
                            <DropDown
                                name="offer_id"
                                formik={formik}
                                value={formik.values.offer_id}
                                onChange={(value) => formik.setFieldValue('offer_id', value)}
                                options={offers[formik.values.channel]}
                                placeholder="Select an offer"
                                optionClass={"px-2 border-bottom"}
                                renderOption={({ label, channel, nr, departure, customer }) => {

                                    return (
                                        <div>
                                            <span className='fw-medium'>{label}</span>
                                            <div className="row gx-2">
                                                <div className="col-auto"><small>{channel}</small></div>
                                                <div className="col-auto"><small>Offer #{nr}</small></div>
                                                <div className="col-auto"><small>{moment(departure).format('DD.MM.YY')}</small></div>
                                                <div className="col-auto"><small>{customer}</small></div>
                                            </div>


                                        </div>
                                    )
                                }}
                            />
                        }
                    </div>

                    <div className="row gx-1 py-2">
                        <div className="col-4">
                            <FormikInput
                                name="adults"
                                label="Adults (16+)"
                                formik={formik}
                                type="number"
                            />
                        </div>
                        <div className="col-4">
                            <FormikInput
                                name="youth"
                                label="Young adults (12-15)"
                                formik={formik}
                                type="number"
                            />
                        </div>
                        <div className="col-4">
                            <FormikInput
                                name="children"
                                label="Children (0-11)"
                                formik={formik}
                                type="number"
                            />
                        </div>
                    </div>
                    <FormikInput
                        name="departure"
                        label="Departure date"
                        formik={formik}
                        type="date"
                    />
                </div>
                <div className="row justify-content-between">
                    <div className="col-auto">
                        <button type="button" onClick={() => {
                            formik.setFieldValue('trip_id', null)
                            formik.setFieldValue('offer_id', null)
                            setStep(1)
                        }} className="btn btn-sm btn-grey">Previous</button>
                    </div>
                    <div className="col-auto">
                        <button type="submit" className="btn btn-sm btn-primary">Create draft</button>
                    </div>
                </div>
            </div>
        )
    }

    const Loading = () => {
        return (
            <div className='loading' onClick={() => setLoading(false)}>
                <FontAwesomeIcon icon={['fad', 'spinner-third']} spin className='text-white' size='3x' />
            </div>
        )
    }

    return (
        <div>
            {loading ? <Loading /> : null}
            <div className="row">
                <div className="col">
                    <h3>Create Offer</h3>
                </div>
                <div className="col-auto">
                    <button className="btn btn-link text-dark" onClick={() => setModalOpen(false)}><FontAwesomeIcon icon={['far', 'times']} size="" /></button>
                </div>
            </div>
            <form onSubmit={formik.handleSubmit} style={{ overflowX: 'hidden' }}>
                <div className=''>
                    <div className='switch-wrapper' ref={nodeRef} style={{ minHeight: height }}>
                        <SwitchTransition mode='out-in'>
                            <CSSTransition
                                key={step}
                                nodeRef={nodeRef}
                                timeout={250}
                                classNames={`${step === 1 ? 'switch-backwards' : 'switch-forwards'}`}
                            >

                                <div ref={nodeRef}>
                                    {step === 1 ? <FormStepOne /> : <FormStepTwo />}
                                </div>

                            </CSSTransition>
                        </SwitchTransition>
                    </div>
                </div>
            </form>
        </div>
    )
}