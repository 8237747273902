import { createContext, useEffect, useState } from "react";
import axios from 'axios'
import { toast } from "react-toastify";
import { useLocation } from "react-router";
import Login from "../screens/Login";
import { status } from "../components/Functions";
import ViewAttachment from "../components/ViewAttachment";
export const AuthContext = createContext({
    accessToken: null,
    user: null
})

export const AuthProvider = ({ children }) => {

    const APIURL = 'https://api.safariresor.se/'
    const [accessToken, setAccessToken] = useState(null)
    const [user, setUser] = useState(null)
    const [notifications, setNotifications] = useState(null)
    const [sellers, setSellers] = useState(null)
    const [viewAttachment, setViewAttachment] = useState(null)
    const [streamerMode, setStreamerMode] = useState(false)


    useEffect(() => {
        if (accessToken) {
            if (!user) {
                getUser()
            }
            if (!sellers) {
                getSellers()
            }
        }
    }, [user, accessToken, sellers])

    useEffect(() => {


        const token = localStorage.getItem('sr_access_token')
        if (token) setAccessToken(token)
    }, [])

    const getSellers = async () => {
        const d = await apiCall({ action: 'getSellerList' })
        if (d.status === 1) setSellers(d.data)
    }

    const reloadNotifications = async () => {
        const d = await apiCall({ action: 'getNotifications' })
        setNotifications(d)
    }

    const getUser = async () => {
        const data = await apiCall({ action: 'getUserByToken' })
        setUser(data)
    }

    const apiCall = async (data) => {

        const status = await axios({
            method: 'post',
            url: `${APIURL}?${data.action}`,
            data: data,
            headers: {
                "Content-Type": "multipart/form-data",
                Token: `${accessToken}`
            }
        })

        if (status.data.status === 5 || status.data.status === 4) {
            //toast.error(status.data.message)
            //localStorage.removeItem('sr_access_token')
            setAccessToken(null)
            setUser(null)
        }

        return status.data
    }

    const downloadAttachment = async (fileObject, blob = false) => {

        const result = await axios({
            url: `${APIURL}?downloadAttachment`,
            data: { action: 'downloadAttachment', fileData: fileObject },
            method: 'POST',
            responseType: 'blob', // important
            headers: {
                "Content-Type": "multipart/form-data",
                Token: `${accessToken}`
            }
        }).then((response) => {
            if (blob) return {
                ...fileObject,
                blob: new Blob([response.data], { type: response.headers['content-type'] })
            }
            const url = window.URL.createObjectURL(new Blob([response.data]));


            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileObject.name); //or any other extension
            document.body.appendChild(link);
            link.click();
        });

        if (blob && result) return result
    }

    const previewAttachment = async (fileObject) => {
        const result = await downloadAttachment(fileObject, true)
        if (result) {
            setViewAttachment(result)
        }
        /*
        const result = await axios({
            url: `${APIURL}?previewAttachment`,
            data: {action: 'previewAttachment', fileData: fileObject},
            method: 'POST',
            headers: { 
                "Content-Type": "application/json",
                Token: `${accessToken}`
            }
        }).then((response) => {
            return { 
                uri: `data:${response.headers['content-type']};base64,${response.data}`,
                fileType: response.headers['content-type']
            }
        });
        if(result){
            setViewAttachment(result)
        }
            */
    }


    const downloadInvoice = async (fileObject) => {

        await axios({
            url: `${APIURL}?downloadInvoice`,
            data: { action: 'downloadInvoice', fileData: fileObject },
            method: 'POST',
            responseType: 'blob', // important
            headers: {
                "Content-Type": "multipart/form-data",
                Token: `${accessToken}`
            }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileObject.name); //or any other extension
            document.body.appendChild(link);
            link.click();
        });
    }

    const login = async (username, password) => {

        const d = await apiCall({
            action: 'login',
            username,
            password
        })


        if (d.status !== 1) {
            status(d)
        }
        if (d.token) {
            localStorage.setItem('sr_access_token', d.token)
            setAccessToken(d.token)

        }

        if (d.user) {
            setUser(d.user)
        }
    }

    return <AuthContext.Provider value={{
        APIURL,
        accessToken,
        user,
        notifications,
        sellers,
        reloadNotifications,
        apiCall,
        downloadAttachment,
        downloadInvoice,
        previewAttachment,
        viewAttachment,
        streamerMode,
        setStreamerMode,
            login,
        logout: () => {
            localStorage.removeItem('sr_access_token')
            setAccessToken(null)
            setUser(null)
        }
    }}>{accessToken && user ?
        <div className={`auth-provider ${streamerMode ? 'streamer-mode' : ''}`}>

            {children}
            {viewAttachment !== null && <ViewAttachment file={viewAttachment} />}
        </div>
        : <Login />}</AuthContext.Provider>
}