import { useContext, useEffect, useRef, useState } from "react"
import { AuthContext } from "../providers/AuthProvider"
import { drawProfileBubble, status } from "./Functions"
import moment from "moment"
import { confirm } from "./Confirmation"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom"
import Rte from "./Rte"
import FileUpload from "./FileUpload"

export default function InlineChat({ id, assignment, nr, type }){

    
    const { apiCall, user } = useContext(AuthContext)

    const [sortedMessages, setSortedMessages] = useState(null)
    const [messages, setMessages] = useState(null)
    const [message, setMessage] = useState('')
    const [users, setUsers] = useState([user])
    const [files, setFiles] = useState([])
    const ref = useRef()
    const editorRef = useRef()

    const [disabled, setDisabled] = useState(false)

    useEffect(() => {
        loadConversation()
    }, [])

    useEffect(() => {
        if(messages){
            setUsers([...messages.users, user])
            const sorted = messages.messages.sort(function(a,b){
                return new Date(a.createdon) - new Date(b.createdon);
            })
            
            setSortedMessages(sorted)
        }

    }, [messages])

    const loadConversation = async () => {
        if(assignment){
            const d = await apiCall({action: 'getConversationMessages', id: assignment})
            if(d.status === 1){
                setMessages(d.data)
            }else{
                status(d)
            }
        }
    }

    useEffect(() => {
        if(ref && ref.current) ref.current.scrollTop = ref.current.scrollHeight
    }, [sortedMessages])

   useEffect(() => {
        if(ref && ref.current) ref.current.scrollTop = ref.current.scrollHeight
    }, [ref])

    const newMessage = async (e) => {
        e.preventDefault()
        
        if(!message.length) return
        setDisabled(true)
        const m = {
            id: sortedMessages.length + 1,
            from: user.id,
            message: message,
            state: {
                id: id,
                no: nr,
                type: type
            },
            createdon: moment().format('YYYY-MM-DD HH:mm')
        }
        if(files.length){
            m.files = files
        }
        console.log(m)
        const d = await apiCall({action: 'newMessage', assignment: assignment, m})
        
        if(d.status === 1){
            setSortedMessages([
                ...sortedMessages,
                m
            ])
            setMessage('')
            setFiles([])
            if(editorRef.current){
            
                editorRef.current.commands.clearContent()
                editorRef.current.commands.setContent()
            }
        }else{
            status(d)
        }

        setDisabled(false)
    }

    const MessageItem = (props) => {
        const { id, from, message, createdon, state, files } = props
        const messageUser = users.filter((u) => u.id === parseInt(from))[0]
        const me = messageUser?.id === user?.id
        const getUrl = () => {
            switch(state.type){
                case 'Booking': return 'bookings'
                case 'Offer': return 'offers'
                case 'Inquiry': return 'inquiries'
                default: return ''
            }
        }

        const deleteMessage = async () => {
            if(await confirm('Delete message', 'Are you sure you wan\'t to delete this message ?', {label: 'Yes, delete', type: 'danger'})){
                const d = await apiCall({action: 'deleteAssignmentMessage', id: id, assignment: assignment})
                status(d)
                if(d.status === 1){
                    setSortedMessages(sortedMessages.filter((m) => m.id !== id))
                }
                
            }
        }

        const { downloadAttachment, previewAttachment } = useContext(AuthContext)
        
        const downloadFile = async ({ name, path, type}) => {
            await downloadAttachment({
                name: name,
                path: path,
                type: type
            })
        }

        const FileItem = ({ name, type, size, path }) => {
            return (
                <div className="row gx-2">
                <div className="col-auto">
                    <FontAwesomeIcon className="pointer text-primary" size="xs" icon={['fas', 'magnifying-glass']} onClick={() => previewAttachment({name, path, type})} />
                </div>
                <div className="col-auto">
                    <FontAwesomeIcon className="pointer text-primary" size="xs" icon={['fas', 'paperclip']} onClick={() => downloadFile({name, path, type})} />
                </div>
                <div className="col">
                    <small className="pointer text-primary" onClick={() => downloadFile({name, path, type})}>
                        <u style={{textDecoration: 'none'}}>{name}</u>
                    </small>
                </div>
            </div>
            )
        }
        return (
            <div className={`message-container ${me ? 'message-from-me' : ''}`}>
                <div className="message-bubble-wrapper">
                    <small className="hint-text d-block">{moment(createdon).format('MMMM D, YYYY [at] HH:mm')} <FontAwesomeIcon onClick={() => deleteMessage()} icon={['far', 'trash-alt']} title="Delete this message" className="ms-2 text-danger pointer" /></small>
                    {state ? <small><Link to={`/${getUrl()}/${state.id}`}>#{state.no}</Link> {state.type}</small> : null}
                    <div className="message-bubble">
                        <pre dangerouslySetInnerHTML={{__html: message.trim()}} className="sensitive"></pre>
                        {
                            files && files.length ? files.map((v,k) => <FileItem key={k} {...v} />) : null
                        }
                    </div>
                    <div className="message-user">
                        <div className="row gx-2 align-items-center">
                            <div className="col-auto">
                                <div className="profile-image">
                                    {messageUser ? drawProfileBubble(messageUser) : 'XX'}
                                </div>
                            </div>
                            <div className="col">
                                <span className="d-block sensitive"><a href="#">{messageUser ? messageUser?.fullname : 'Unknown / deleted'}</a></span>
                                <span className="d-block sensitive"><a href="#">{messageUser?.phone}</a></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    if(!sortedMessages) {
        return (
            <div className="d-flex justify-content-center align-items-center flex-column h-100">
                <div className="text-center">
                    <FontAwesomeIcon icon={['fad', 'spinner-third']} spin size="10x" className="text-success mb-3" />
                    <h5>Loading conversation..</h5>
                </div>
            </div>
        )
    }

    
    return (
        <>
            <div className="overscroll d-flex flex-column flex-grow-1 flex-basis-0 p-0" style={{maxHeight: 500}} ref={ref}>
                {
                    sortedMessages.length ? sortedMessages.map((item, key) => <MessageItem key={key} {...item} />) : <small className="py-3">No messages yet</small>
                }
            </div>
            <form onSubmit={newMessage}>
                <div className="form-group mt-2">
                    <div className="row align-items-center">
                        <div className="col mb-2">
                            <div className="mb-2">
                                <Rte
                                    editorRef={editorRef}
                                    linkActive
                                    content={message} 
                                    setContent={(e) => setMessage(e)} 
                                />
                            </div>
                            <div className="mb-md-0 mb-2">
                                <FileUpload
                                    label="Upload tickets / documents"
                                    files={files}
                                    setFiles={(e) => setFiles(e)}
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <button className="btn btn-primary btn-sm w-100" type="submit" disabled={disabled} >
                                <div className="row gx-2 justify-content-center">
                                    <div className="col-auto">Send</div>
                                    {disabled ? <div className="col-auto"><FontAwesomeIcon icon={['far', 'spinner-third']} spin /></div> : null}
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )

}